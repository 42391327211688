define('ember-primer/helpers/extent', ['exports', 'd3-array'], function (exports, _d3Array) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.helper = helper;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function helper(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        array = _ref2[0];

    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var finalArray = [];

    if (hash && hash.key) {
      var match = hash.key.match(/\$(\d+)/);
      if (match) {
        var _match = _slicedToArray(match, 2),
            index = _match[1];

        finalArray = array.map(function (d) {
          return d[index];
        });
      } else {
        finalArray = array.map(function (d) {
          return d[hash.key];
        });
      }
    } else {
      finalArray = array;
    }

    if (hash && hash.inclusive === true) {
      return finalArray;
    } else {
      return (0, _d3Array.extent)(finalArray.sort(_d3Array.ascending));
    }
  }

  exports.default = Ember.Helper.helper(helper);
});