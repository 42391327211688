define('ember-primer/helpers/p/transform-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pTransformDate = pTransformDate;
  function pTransformDate() /* params , hash*/{
    return function (d) {
      return new Date(d);
    };
  }

  exports.default = Ember.Helper.helper(pTransformDate);
});