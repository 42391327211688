define('ember-primer/components/primer-line/component', ['exports', 'ember-primer/components/primer-line/template', 'd3-shape', 'ember-primer/utils/curve-lookup'], function (exports, _template, _d3Shape, _curveLookup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LineComponent = Ember.Component.extend({
    tagName: 'g',
    layout: _template.default,

    /**
     * Normalized values to render
     * @public
     * @type {Array<Array[2]>}
     */
    values: [],

    /**
     * Line interpolation
     * @public
     * @type {String}
     */
    interpolation: 'monotone-x',

    /**
     * Line stroke color
     * @public
     * @type {String}
     */
    stroke: '#4285f4',

    /**
     * Line stroke width
     *
     * @public
     * @default 1
     * @type {Number}
     */
    strokeWidth: 1,

    /**
     * The width of the transparent event target following this line.
     *
     * @public
     * @default 10
     * @type {Number}
     */
    eventTargetWidth: 10,

    /**
     * Line cap endings
     * @public
     * @type {String}
     */
    strokeLineCap: 'round',

    /**
     * Fill color
     * @public
     * @type {String}
     */
    fill: 'none',

    /**
     * X Offset to position line from
     * @public
     * @type {Number}
     */
    x: 0,

    /**
     * Y Offset to position line from
     * @public
     * @type {Number}
     */
    y: 0,

    /**
     * Used to position the path with an offset if needed from left,top. It computes
     * a translate string for the transform attribute.
     *
     * @param  {Number} 'x' Offset X
     * @param  {Number} 'y' Offset Y
     * @return {String} Transform(Translate(x,y))
     * @protected
     */
    transform: Ember.computed('x', 'y', {
      get: function get() {
        var _getProperties = this.getProperties('x', 'y'),
            x = _getProperties.x,
            y = _getProperties.y;

        return 'translate(' + x + ',' + y + ')';
      }
    }),

    /**
     * Returns the computed path data for the path element everytime the values change.
     *
     * @protected
     * @returns {String} SVG Path Data
     */
    pathData: Ember.computed('values.[]', 'interpolation', {
      get: function get() {
        var _getProperties2 = this.getProperties('values', 'interpolation'),
            values = _getProperties2.values,
            interpolation = _getProperties2.interpolation;

        var lineFn = (0, _d3Shape.line)().x(function (d) {
          return d[0];
        }).y(function (d) {
          return d[1];
        });

        if (interpolation) {
          lineFn.curve((0, _curveLookup.default)(interpolation));
        }

        return lineFn(values);
      }
    }),

    mouseMove: function mouseMove(event) {
      var offsetX = event.offsetX,
          offsetY = event.offsetY;

      this.sendAction('mouse-move', [offsetX, offsetY]);
    }
  });

  LineComponent.reopenClass({
    positionalParams: ['values']
  });

  exports.default = LineComponent;
});