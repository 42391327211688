define('ember-measure/with-content-rect', ['exports', 'ember-measure/mixins/with-content-rect'], function (exports, _withContentRect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _withContentRect.default;
    }
  });
});