define('ember-primer/components/primer-container/component', ['exports', 'ember-primer/components/primer-container/template', 'ember-primer/utils/box-expression'], function (exports, _template, _boxExpression) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var keys = Object.keys;

  var Container = Ember.Component.extend({
    tagName: '',

    layout: _template.default,

    xScale: null,

    yScale: null,

    width: null,

    height: null,

    margin: '0',
    padding: '0',

    cursorPosition: [0, 0],

    xCursor: -1,
    yCursor: -1,

    localizedXScale: Ember.computed('xScale', 'rect', {
      get: function get() {
        var _getProperties = this.getProperties('xScale', 'rect'),
            xScale = _getProperties.xScale,
            rect = _getProperties.rect;

        return xScale.copy().range([0, rect.width]);
      }
    }),

    localizedYScale: Ember.computed('yScale', 'rect', {
      get: function get() {
        var _getProperties2 = this.getProperties('yScale', 'rect'),
            yScale = _getProperties2.yScale,
            rect = _getProperties2.rect;

        return yScale.copy().range([rect.height, 0]);
      }
    }),

    rect: Ember.computed('width', 'height', 'margin', {
      get: function get() {
        var outerHeight = this.get('height') || 10;
        var outerWidth = this.get('width') || 10;
        var margin = (0, _boxExpression.default)(this.get('margin'));
        // let padding = box(this.get('padding'));

        // let innerWidth = outerWidth - margin.left - margin.right;
        // let innerHeight = outerHeight - margin.top - margin.bottom;
        // let width = innerWidth - padding.left - padding.right;
        // let height = innerHeight - padding.top - padding.bottom;

        // let rect = {
        //   padding,
        //   margin,
        //   outerWidth, outerHeight,
        //   innerWidth, innerHeight,
        //   width, height,
        //   top: margin.top + padding.top,
        //   left: margin.left + padding.left
        //   // right: margin.left + padding.left,
        // };

        // // let height = this.get('height') || 10;
        // // let width = this.get('width') || 10;
        // // let margin = box(this.get('margin'));
        // // let padding = box(this.get('padding'));

        var innerWidth = outerWidth - margin.left - margin.right;
        var innerHeight = outerHeight - margin.top - margin.bottom;

        var rect = {
          margin: margin,
          top: margin.top,
          left: margin.left,
          bottom: innerHeight - margin.top,
          right: innerWidth - margin.right,
          outerWidth: outerWidth,
          outerHeight: outerHeight,
          innerWidth: innerWidth,
          innerHeight: innerHeight,

          // Dupes
          height: innerHeight,
          width: innerWidth
        };

        keys(rect).forEach(function (key) {
          if (rect[key] < 0) {
            rect[key] = 0;
          }
        });

        return rect;
      }
    }),

    actions: {
      updateCursorPosition: function updateCursorPosition(_ref, _ref2) {
        var _ref4 = _slicedToArray(_ref, 2),
            xValue = _ref4[0],
            yValue = _ref4[1];

        var _ref3 = _slicedToArray(_ref2, 2),
            xCursor = _ref3[0],
            yCursor = _ref3[1];

        this.sendAction('update-cursor-position', [xValue, yValue], [xCursor, yCursor]);

        // this.setProperties({ cursorPosition: [xCursor, yCursor] });
        // this.sendAction('_cursorChangedPosition', [xValue, yValue], [xCursor, yCursor]);
      }
    }
  });

  Container.reopenClass({
    positionalParams: ['xScale', 'yScale']
  });

  exports.default = Container;
});