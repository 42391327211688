define('ember-primer/helpers/scale/ordinal', ['exports', 'd3-scale', 'ember-primer/utils/scale-helper-options'], function (exports, _d3Scale, _scaleHelperOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.scaleOrdinal = scaleOrdinal;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function scaleOrdinal(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        domain = _ref2[0],
        range = _ref2[1];

    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var scale = (0, _d3Scale.scaleOrdinal)();
    (0, _scaleHelperOptions.default)(scale, domain, range, hash);
    return scale;
  }

  exports.default = Ember.Helper.helper(scaleOrdinal);
});