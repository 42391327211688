define('ember-primer/utils/scale-helper-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = scaleHelperOptions;
  function scaleHelperOptions(scale, domain, range, hash) {
    scale.domain(domain || []);

    // Add the range
    if (hash && hash.round) {
      scale.rangeRound(range || []);
    } else {
      scale.range(range || []);
    }

    // Add Clamping
    if (hash && Ember.isPresent(hash.clamp)) {
      scale.clamp(hash.clamp);
    }

    // Add niceness
    if (hash && Ember.isPresent(hash.nice)) {
      scale.nice(hash.nice);
    }

    return scale;
  }
});