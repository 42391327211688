define('ember-primer/mixins/resizeable-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MutObserver = self.window.MutationObserver || self.window.WebKitMutationObserver;

  exports.default = Ember.Mixin.create({

    minHeight: 50,

    minWidth: 50,

    width: null,

    height: null,

    init: function init() {
      var _this = this;

      this._super();

      this.runloopAwareResize = function () {
        Ember.run.join(_this, _this.didResize);
      };
    },
    addGlobalEvents: function addGlobalEvents() {
      window.addEventListener('resize', this.runloopAwareResize, { passive: true });
      window.addEventListener('orientationchange', this.runloopAwareResize, { passive: true });
    },
    removeGlobalEvents: function removeGlobalEvents() {
      window.removeEventListener('resize', this.runloopAwareResize);
      window.removeEventListener('orientationchange', this.runloopAwareResize);
    },
    startObservingDomMutations: function startObservingDomMutations() {
      var _this2 = this;

      if (MutObserver) {
        this.mutationObserver = new MutObserver(function (mutations) {
          if (mutations[0].addedNodes.length || mutations[0].removedNodes.length) {
            _this2.runloopAwareResize();
          }
        });
        this.mutationObserver.observe(this.element, { childList: true, subtree: true });
      } else {
        this.element.addEventListener('DOMNodeInserted', this.runloopAwareResize, false);
        this.element.addEventListener('DOMNodeRemoved', this.runloopAwareResize, false);
      }
    },
    stopObservingDomMutations: function stopObservingDomMutations() {
      if (MutObserver) {
        if (this.mutationObserver) {
          this.mutationObserver.disconnect();
          this.mutationObserver = null;
        }
      } else {
        if (this.element) {
          this.element.removeEventListener('DOMNodeInserted', this.runloopAwareReposition);
          this.element.removeEventListener('DOMNodeRemoved', this.runloopAwareReposition);
        }
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('width') === null) {
        this.addGlobalEvents();
        // this.startObservingDomMutations();

        // window.addEventListener(`resize.${ this.elementId }`, this.didResize.bind(this));
        Ember.run.scheduleOnce('render', this, this.measureDimensions);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.removeGlobalEvents();
    },
    didResize: function didResize() {
      Ember.run.throttle(this, this.measureDimensions, 16);
    },


    /**
     * Calculates dimensions of this container. This forces the browser to calculate
     * styles, which is slow, so don't do this too often or you'll create jank.
     *
     * @public
     */
    measureDimensions: function measureDimensions() {
      if (!this.element) {
        return;
      }

      var _getProperties = this.getProperties('minWidth', 'minHeight'),
          minWidth = _getProperties.minWidth,
          minHeight = _getProperties.minHeight;

      var rect = this.element.getBoundingClientRect();
      this.setProperties({
        width: Math.max(rect.width, minWidth),
        height: Math.max(rect.height, minHeight)
      });
    }
  });
});