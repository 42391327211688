define("ember-attacher/templates/components/attach-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nRLbETay",
    "block": "{\"symbols\":[\"emberPopper\",\"&default\"],\"statements\":[[1,[30,[36,18],[[35,17]],null]],[2,\"\\n\\n\"],[6,[37,6],[[30,[36,23],[[35,10],[30,[36,22],[[30,[36,21],[[35,20]],null],[35,19]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,16],null,[[\"ariaRole\",\"class\",\"eventsEnabled\",\"id\",\"modifiers\",\"placement\",\"popperContainer\",\"popperTarget\",\"registerAPI\",\"renderInPlace\"],[[35,15],\"ember-attacher\",false,[35,14],[35,13],[35,12],[35,11],[35,10],[30,[36,3],[[32,0],\"registerAPI\"],null],[35,9]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[34,1]]]],[15,5,[34,2]],[12],[2,\"\\n      \"],[18,2,[[30,[36,4],null,[[\"emberPopper\",\"hide\"],[[32,1],[30,[36,3],[[32,0],\"hide\"],null]]]]]],[2,\"\\n\\n\"],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,\"x-arrow\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[30,[36,8],[[35,7],\"fill\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,\"x-circle\",\"\"],[15,5,[34,0]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"_circleTransitionDuration\",\"_class\",\"_style\",\"action\",\"hash\",\"arrow\",\"if\",\"animation\",\"eq\",\"renderInPlace\",\"_currentTarget\",\"popperContainer\",\"placement\",\"_modifiers\",\"id\",\"ariaRole\",\"ember-popper\",\"_parentFinder\",\"unbound\",\"_mustRender\",\"lazyRender\",\"not\",\"or\",\"and\"]}",
    "meta": {
      "moduleName": "ember-attacher/templates/components/attach-popover.hbs"
    }
  });

  _exports.default = _default;
});