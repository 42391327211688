define('ember-primer/utils/find-closest-cursor-points', ['exports', 'ember-primer/utils/binary-closest-search', 'ember-primer/utils/unsorted-closest-search'], function (exports, _binaryClosestSearch, _unsortedClosestSearch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = findClosestCursorPoints;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function findClosestCursorPoints(_ref, _ref2, xScale, yScale, values) {
    var _ref4 = _slicedToArray(_ref, 2),
        x = _ref4[0],
        y = _ref4[1];

    var _ref3 = _slicedToArray(_ref2, 2),
        xOffset = _ref3[0],
        yOffset = _ref3[1];

    var valueAtX = xScale.invert(x - xOffset);
    var valueAtY = yScale.invert(y - yOffset);

    // 1. Find closest data point to current cursor X
    var index = (0, _binaryClosestSearch.default)(+valueAtX, values.map(function (_ref5) {
      var _ref6 = _slicedToArray(_ref5, 1),
          d = _ref6[0];

      return +d;
    }));

    // 2 Find the closest series on the Y axis in case we have more than one.

    var _values$index = _slicedToArray(values[index], 2),
        xValue = _values$index[0],
        yValues = _values$index[1];

    var _closestUnsorted = (0, _unsortedClosestSearch.default)(+valueAtY, yValues),
        _closestUnsorted2 = _slicedToArray(_closestUnsorted, 2),
        yValue = _closestUnsorted2[0],
        closestYIndex = _closestUnsorted2[1];

    // 3. Scale values back to x,y
    var xPointer = xScale(xValue);
    var yPointer = yScale(yValue);

    return [[xPointer, yPointer], values[index], closestYIndex];
  }
});