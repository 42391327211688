define('ember-primer/components/primer-candle/component', ['exports', 'ember-primer/components/primer-candle/template', 'ember-computed-style'], function (exports, _template, _emberComputedStyle) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var assign = Object.assign;
	exports.default = Ember.Component.extend({
		layout: _template.default,
		tagName: 'g',

		classNames: ['Primer-Candle'],

		x: 0,
		y: 0,
		y1: 1,
		y2: 1,
		candleWidth: 8,
		candleHeight: 20,
		style: {},
		datum: {},

		_candleStyle: (0, _emberComputedStyle.default)('style'),

		/**
    * Specifies the shape-rendering to apply to the candle
    *
    * @public
    * @property
    */
		shapeRendering: 'auto',

		role: 'presentation',

		candle: Ember.computed('candleWidth', {
			get: function get() {
				var _getProperties = this.getProperties('candleWidth'),
				    candleWidth = _getProperties.candleWidth;

				return {
					x: candleWidth / 2
				};
			}
		}),

		wick: Ember.computed('candleWidth', {
			get: function get() {
				var _getProperties2 = this.getProperties('candleWidth'),
				    candleWidth = _getProperties2.candleWidth;

				return {
					x: candleWidth / 2
				};
			}
		})
	});
});