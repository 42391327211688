define('ember-primer/components/primer-plot/component', ['exports', 'ember-measure/with-content-rect', 'ember-primer/components/primer-plot/template'], function (exports, _withContentRect, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend(_withContentRect.default, {
    layout: _template.default,
    tagName: 'div',

    classNames: ['Primer-Chart'],

    // attributeBindings: ['width', 'height', 'viewBox', 'ariaLabelledBy:aria-labelledby', 'role'],

    containerComponentName: 'primer-container',

    title: null,
    desc: null,
    ariaLabelledBy: 'title desc',
    role: 'img',

    cursorPosition: [0, 0],

    // TODO: Rename types to rectTypes
    types: ['client'],

    width: Ember.computed.reads('contentRect.client.width'),
    height: Ember.computed.reads('contentRect.client.height'),

    viewBox: Ember.computed('width', 'height', {
      get: function get() {
        var _getProperties = this.getProperties('width', 'height'),
            width = _getProperties.width,
            height = _getProperties.height;

        if (!width || !height) {
          return undefined;
        }

        return [0, 0, width, height].join(' ');
      }
    }),

    actions: {
      updateCursorPosition: function updateCursorPosition(_ref, _ref2) {
        var _ref4 = _slicedToArray(_ref, 2),
            xValue = _ref4[0],
            yValue = _ref4[1];

        var _ref3 = _slicedToArray(_ref2, 2),
            xCursor = _ref3[0],
            yCursor = _ref3[1];

        this.sendAction('cursor-moved', [xValue, yValue], [xCursor, yCursor]);
      }
    }
  });
});