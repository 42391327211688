define('ember-code-block/initializers/register-hljs-langauges', ['exports', 'highlight/register'], function (exports, _register) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.initialize = initialize;
	function initialize() /* application */{
		(0, _register.default)();
	}

	exports.default = {
		initialize: initialize
	};
});