define('ember-primer/components/primer-area/component', ['exports', 'ember-primer/components/primer-area/template', 'd3-shape', 'ember-primer/utils/curve-lookup'], function (exports, _template, _d3Shape, _curveLookup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var LineComponent = Ember.Component.extend({
    tagName: 'g',
    layout: _template.default,

    /**
     * Normalized values to render
     * @public
     * @type {Array<Array[2]>}
     */
    values: [],

    /**
     * Line interpolation
     * @public
     * @type {String}
     */
    interpolation: 'monotone-x',

    /**
     * Line stroke color
     * @public
     * @type {String}
     */
    stroke: '#81c784',

    /**
     * Line stroke width
     *
     * @public
     * @default 1
     * @type {Number}
     */
    strokeWidth: 1,

    /**
     * The width of the transparent event target following this line.
     *
     * @public
     * @default 10
     * @type {Number}
     */
    eventTargetWidth: 10,

    /**
     * Line cap endings
     * @public
     * @type {String}
     */
    strokeLineCap: 'round',

    /**
     * Fill color
     * @public
     * @type {String}
     */
    fill: '#81c784',

    /**
     * Fill opacity
     * @public
     * @type {Number}
     */
    fillOpacity: 0.24,

    /**
     * X Offset to position line from
     * @public
     * @type {Number}
     */
    x: 0,

    /**
     * Y Offset to position line from
     * @public
     * @type {Number}
     */
    y: 0,

    /**
     * Used to position the path with an offset if needed from left,top. It computes
     * a translate string for the transform attribute.
     *
     * @param  {Number} 'x' Offset X
     * @param  {Number} 'y' Offset Y
     * @return {String} Transform(Translate(x,y))
     * @protected
     */
    transform: Ember.computed('x', 'y', {
      get: function get() {
        var _getProperties = this.getProperties('x', 'y'),
            x = _getProperties.x,
            y = _getProperties.y;

        return 'translate(' + x + ',' + y + ')';
      }
    }),

    /**
     * yScale for scaling y0
     *
     * @private
     * @type {D3Scale}
     */
    yScale: null,

    topLineStroke: '#81c784',

    topLineStrokeWidth: 1,

    /**
     * Returns the computed path data for the path element everytime the values change.
     *
     * @protected
     * @returns {String} SVG Path Data
     */
    areaFn: Ember.computed('interpolation', 'yScale', {
      get: function get() {
        var _getProperties2 = this.getProperties('interpolation', 'yScale'),
            interpolation = _getProperties2.interpolation,
            yScale = _getProperties2.yScale;

        var xAccessor = this.getWithDefault('xAccessor', function (d) {
          return d[0];
        });
        var yAccessor = this.getWithDefault('yAccessor', function (d) {
          return d[1];
        });

        var _yScale$domain = yScale.domain(),
            _yScale$domain2 = _slicedToArray(_yScale$domain, 1),
            zero = _yScale$domain2[0];

        var areaFn = (0, _d3Shape.area)().x(xAccessor).y0(yAccessor).y1(yScale(zero));

        if (interpolation) {
          areaFn.curve((0, _curveLookup.default)(interpolation));
        }

        return areaFn;
      }
    }),

    pathData: Ember.computed('areaFn', 'values.[]', {
      get: function get() {
        var areaFn = this.get('areaFn');
        var values = this.get('values');
        return areaFn(values);
      }
    }),

    lineXPathData: Ember.computed('areaFn', 'values.[]', {
      get: function get() {
        var areaFn = this.get('areaFn');
        var values = this.get('values');
        return areaFn.lineX0()(values);
      }
    }),

    mouseMove: function mouseMove(event) {
      var offsetX = event.offsetX,
          offsetY = event.offsetY;

      this.sendAction('mouse-move', [offsetX, offsetY]);
    }
  });

  LineComponent.reopenClass({
    positionalParams: ['values']
  });

  exports.default = LineComponent;
});