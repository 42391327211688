define('resize-observer-polyfill', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    /**
     * A collection of shims that provide minimal functionality of the ES6 collections.
     *
     * These implementations are not meant to be used outside of the ResizeObserver
     * modules as they cover only a limited range of use cases.
     */
    /* eslint-disable require-jsdoc, valid-jsdoc */
    var MapShim = function () {
        if (typeof Map !== 'undefined') {
            return Map;
        }

        /**
         * Returns index in provided array that matches the specified key.
         *
         * @param {Array<Array>} arr
         * @param {*} key
         * @returns {number}
         */
        function getIndex(arr, key) {
            var result = -1;

            arr.some(function (entry, index) {
                if (entry[0] === key) {
                    result = index;

                    return true;
                }

                return false;
            });

            return result;
        }

        return function () {
            function _class() {
                _classCallCheck(this, _class);

                this.__entries__ = [];
            }

            /**
             * @returns {boolean}
             */


            _createClass(_class, [{
                key: 'get',
                value: function get(key) {
                    var index = getIndex(this.__entries__, key);
                    var entry = this.__entries__[index];

                    return entry && entry[1];
                }
            }, {
                key: 'set',
                value: function set(key, value) {
                    var index = getIndex(this.__entries__, key);

                    if (~index) {
                        this.__entries__[index][1] = value;
                    } else {
                        this.__entries__.push([key, value]);
                    }
                }
            }, {
                key: 'delete',
                value: function _delete(key) {
                    var entries = this.__entries__;
                    var index = getIndex(entries, key);

                    if (~index) {
                        entries.splice(index, 1);
                    }
                }
            }, {
                key: 'has',
                value: function has(key) {
                    return !!~getIndex(this.__entries__, key);
                }
            }, {
                key: 'clear',
                value: function clear() {
                    this.__entries__.splice(0);
                }
            }, {
                key: 'forEach',
                value: function forEach(callback) {
                    var ctx = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
                    var _iteratorNormalCompletion = true;
                    var _didIteratorError = false;
                    var _iteratorError = undefined;

                    try {
                        for (var _iterator = this.__entries__[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                            var entry = _step.value;

                            callback.call(ctx, entry[1], entry[0]);
                        }
                    } catch (err) {
                        _didIteratorError = true;
                        _iteratorError = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion && _iterator.return) {
                                _iterator.return();
                            }
                        } finally {
                            if (_didIteratorError) {
                                throw _iteratorError;
                            }
                        }
                    }
                }
            }, {
                key: 'size',
                get: function get() {
                    return this.__entries__.length;
                }
            }]);

            return _class;
        }();
    }();

    /**
     * Detects whether window and document objects are available in current environment.
     */
    var isBrowser = typeof window !== 'undefined' && typeof document !== 'undefined' && window.document === document;

    // Returns global object of a current environment.
    var global$1 = function () {
        if (typeof global !== 'undefined' && global.Math === Math) {
            return global;
        }

        if (typeof self !== 'undefined' && self.Math === Math) {
            return self;
        }

        if (typeof window !== 'undefined' && window.Math === Math) {
            return window;
        }

        // eslint-disable-next-line no-new-func
        return Function('return this')();
    }();

    /**
     * A shim for the requestAnimationFrame which falls back to the setTimeout if
     * first one is not supported.
     *
     * @returns {number} Requests' identifier.
     */
    var requestAnimationFrame$1 = function () {
        if (typeof requestAnimationFrame === 'function') {
            // It's required to use a bounded function because IE sometimes throws
            // an "Invalid calling object" error if rAF is invoked without the global
            // object on the left hand side.
            return requestAnimationFrame.bind(global$1);
        }

        return function (callback) {
            return setTimeout(function () {
                return callback(Date.now());
            }, 1000 / 60);
        };
    }();

    // Defines minimum timeout before adding a trailing call.
    var trailingTimeout = 2;

    /**
     * Creates a wrapper function which ensures that provided callback will be
     * invoked only once during the specified delay period.
     *
     * @param {Function} callback - Function to be invoked after the delay period.
     * @param {number} delay - Delay after which to invoke callback.
     * @returns {Function}
     */
    var throttle = function throttle(callback, delay) {
        var leadingCall = false,
            trailingCall = false,
            lastCallTime = 0;

        /**
         * Invokes the original callback function and schedules new invocation if
         * the "proxy" was called during current request.
         *
         * @returns {void}
         */
        function resolvePending() {
            if (leadingCall) {
                leadingCall = false;

                callback();
            }

            if (trailingCall) {
                proxy();
            }
        }

        /**
         * Callback invoked after the specified delay. It will further postpone
         * invocation of the original function delegating it to the
         * requestAnimationFrame.
         *
         * @returns {void}
         */
        function timeoutCallback() {
            requestAnimationFrame$1(resolvePending);
        }

        /**
         * Schedules invocation of the original function.
         *
         * @returns {void}
         */
        function proxy() {
            var timeStamp = Date.now();

            if (leadingCall) {
                // Reject immediately following calls.
                if (timeStamp - lastCallTime < trailingTimeout) {
                    return;
                }

                // Schedule new call to be in invoked when the pending one is resolved.
                // This is important for "transitions" which never actually start
                // immediately so there is a chance that we might miss one if change
                // happens amids the pending invocation.
                trailingCall = true;
            } else {
                leadingCall = true;
                trailingCall = false;

                setTimeout(timeoutCallback, delay);
            }

            lastCallTime = timeStamp;
        }

        return proxy;
    };

    // Minimum delay before invoking the update of observers.
    var REFRESH_DELAY = 20;

    // A list of substrings of CSS properties used to find transition events that
    // might affect dimensions of observed elements.
    var transitionKeys = ['top', 'right', 'bottom', 'left', 'width', 'height', 'size', 'weight'];

    // Check if MutationObserver is available.
    var mutationObserverSupported = typeof MutationObserver !== 'undefined';

    /**
     * Singleton controller class which handles updates of ResizeObserver instances.
     */

    var ResizeObserverController = function () {

        /**
         * Creates a new instance of ResizeObserverController.
         *
         * @private
         */

        /**
         * A list of connected observers.
         *
         * @private {Array<ResizeObserverSPI>}
         */

        /**
         * Tells that controller has subscribed for Mutation Events.
         *
         * @private {boolean}
         */
        function ResizeObserverController() {
            _classCallCheck(this, ResizeObserverController);

            this.connected_ = false;
            this.mutationEventsAdded_ = false;
            this.mutationsObserver_ = null;
            this.observers_ = [];

            this.onTransitionEnd_ = this.onTransitionEnd_.bind(this);
            this.refresh = throttle(this.refresh.bind(this), REFRESH_DELAY);
        }

        /**
         * Adds observer to observers list.
         *
         * @param {ResizeObserverSPI} observer - Observer to be added.
         * @returns {void}
         */

        /**
         * Holds reference to the controller's instance.
         *
         * @private {ResizeObserverController}
         */

        /**
         * Keeps reference to the instance of MutationObserver.
         *
         * @private {MutationObserver}
         */

        /**
         * Indicates whether DOM listeners have been added.
         *
         * @private {boolean}
         */


        _createClass(ResizeObserverController, [{
            key: 'addObserver',
            value: function addObserver(observer) {
                if (!~this.observers_.indexOf(observer)) {
                    this.observers_.push(observer);
                }

                // Add listeners if they haven't been added yet.
                if (!this.connected_) {
                    this.connect_();
                }
            }
        }, {
            key: 'removeObserver',
            value: function removeObserver(observer) {
                var observers = this.observers_;
                var index = observers.indexOf(observer);

                // Remove observer if it's present in registry.
                if (~index) {
                    observers.splice(index, 1);
                }

                // Remove listeners if controller has no connected observers.
                if (!observers.length && this.connected_) {
                    this.disconnect_();
                }
            }
        }, {
            key: 'refresh',
            value: function refresh() {
                var changesDetected = this.updateObservers_();

                // Continue running updates if changes have been detected as there might
                // be future ones caused by CSS transitions.
                if (changesDetected) {
                    this.refresh();
                }
            }
        }, {
            key: 'updateObservers_',
            value: function updateObservers_() {
                // Collect observers that have active observations.
                var activeObservers = this.observers_.filter(function (observer) {
                    return observer.gatherActive(), observer.hasActive();
                });

                // Deliver notifications in a separate cycle in order to avoid any
                // collisions between observers, e.g. when multiple instances of
                // ResizeObserver are tracking the same element and the callback of one
                // of them changes content dimensions of the observed target. Sometimes
                // this may result in notifications being blocked for the rest of observers.
                activeObservers.forEach(function (observer) {
                    return observer.broadcastActive();
                });

                return activeObservers.length > 0;
            }
        }, {
            key: 'connect_',
            value: function connect_() {
                // Do nothing if running in a non-browser environment or if listeners
                // have been already added.
                if (!isBrowser || this.connected_) {
                    return;
                }

                // Subscription to the "Transitionend" event is used as a workaround for
                // delayed transitions. This way it's possible to capture at least the
                // final state of an element.
                document.addEventListener('transitionend', this.onTransitionEnd_);

                window.addEventListener('resize', this.refresh);

                if (mutationObserverSupported) {
                    this.mutationsObserver_ = new MutationObserver(this.refresh);

                    this.mutationsObserver_.observe(document, {
                        attributes: true,
                        childList: true,
                        characterData: true,
                        subtree: true
                    });
                } else {
                    document.addEventListener('DOMSubtreeModified', this.refresh);

                    this.mutationEventsAdded_ = true;
                }

                this.connected_ = true;
            }
        }, {
            key: 'disconnect_',
            value: function disconnect_() {
                // Do nothing if running in a non-browser environment or if listeners
                // have been already removed.
                if (!isBrowser || !this.connected_) {
                    return;
                }

                document.removeEventListener('transitionend', this.onTransitionEnd_);
                window.removeEventListener('resize', this.refresh);

                if (this.mutationsObserver_) {
                    this.mutationsObserver_.disconnect();
                }

                if (this.mutationEventsAdded_) {
                    document.removeEventListener('DOMSubtreeModified', this.refresh);
                }

                this.mutationsObserver_ = null;
                this.mutationEventsAdded_ = false;
                this.connected_ = false;
            }
        }, {
            key: 'onTransitionEnd_',
            value: function onTransitionEnd_(_ref) {
                var _ref$propertyName = _ref.propertyName,
                    propertyName = _ref$propertyName === undefined ? '' : _ref$propertyName;

                // Detect whether transition may affect dimensions of an element.
                var isReflowProperty = transitionKeys.some(function (key) {
                    return !!~propertyName.indexOf(key);
                });

                if (isReflowProperty) {
                    this.refresh();
                }
            }
        }], [{
            key: 'getInstance',
            value: function getInstance() {
                if (!this.instance_) {
                    this.instance_ = new ResizeObserverController();
                }

                return this.instance_;
            }
        }]);

        return ResizeObserverController;
    }();

    ResizeObserverController.instance_ = null;

    /**
     * Defines non-writable/enumerable properties of the provided target object.
     *
     * @param {Object} target - Object for which to define properties.
     * @param {Object} props - Properties to be defined.
     * @returns {Object} Target object.
     */
    var defineConfigurable = function defineConfigurable(target, props) {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
            for (var _iterator2 = Object.keys(props)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var key = _step2.value;

                Object.defineProperty(target, key, {
                    value: props[key],
                    enumerable: false,
                    writable: false,
                    configurable: true
                });
            }
        } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                    _iterator2.return();
                }
            } finally {
                if (_didIteratorError2) {
                    throw _iteratorError2;
                }
            }
        }

        return target;
    };

    /**
     * Returns the global object associated with provided element.
     *
     * @param {Object} target
     * @returns {Object}
     */
    var getWindowOf = function getWindowOf(target) {
        // Assume that the element is an instance of Node, which means that it
        // has the "ownerDocument" property from which we can retrieve a
        // corresponding global object.
        var ownerGlobal = target && target.ownerDocument && target.ownerDocument.defaultView;

        // Return the local global object if it's not possible extract one from
        // provided element.
        return ownerGlobal || global$1;
    };

    // Placeholder of an empty content rectangle.
    var emptyRect = createRectInit(0, 0, 0, 0);

    /**
     * Converts provided string to a number.
     *
     * @param {number|string} value
     * @returns {number}
     */
    function toFloat(value) {
        return parseFloat(value) || 0;
    }

    /**
     * Extracts borders size from provided styles.
     *
     * @param {CSSStyleDeclaration} styles
     * @param {...string} positions - Borders positions (top, right, ...)
     * @returns {number}
     */
    function getBordersSize(styles) {
        for (var _len = arguments.length, positions = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            positions[_key - 1] = arguments[_key];
        }

        return positions.reduce(function (size, position) {
            var value = styles['border-' + position + '-width'];

            return size + toFloat(value);
        }, 0);
    }

    /**
     * Extracts paddings sizes from provided styles.
     *
     * @param {CSSStyleDeclaration} styles
     * @returns {Object} Paddings box.
     */
    function getPaddings(styles) {
        var positions = ['top', 'right', 'bottom', 'left'];
        var paddings = {};

        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
            for (var _iterator3 = positions[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                var position = _step3.value;

                var value = styles['padding-' + position];

                paddings[position] = toFloat(value);
            }
        } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion3 && _iterator3.return) {
                    _iterator3.return();
                }
            } finally {
                if (_didIteratorError3) {
                    throw _iteratorError3;
                }
            }
        }

        return paddings;
    }

    /**
     * Calculates content rectangle of provided SVG element.
     *
     * @param {SVGGraphicsElement} target - Element content rectangle of which needs
     *      to be calculated.
     * @returns {DOMRectInit}
     */
    function getSVGContentRect(target) {
        var bbox = target.getBBox();

        return createRectInit(0, 0, bbox.width, bbox.height);
    }

    /**
     * Calculates content rectangle of provided HTMLElement.
     *
     * @param {HTMLElement} target - Element for which to calculate the content rectangle.
     * @returns {DOMRectInit}
     */
    function getHTMLElementContentRect(target) {
        var clientWidth = target.clientWidth,
            clientHeight = target.clientHeight;


        // By this condition we can catch all non-replaced inline, hidden and
        // detached elements. Though elements with width & height properties less
        // than 0.5 will be discarded as well.
        //
        // Without it we would need to implement separate methods for each of
        // those cases and it's not possible to perform a precise and performance
        // effective test for hidden elements. E.g. even jQuery's ':visible' filter
        // gives wrong results for elements with width & height less than 0.5.
        if (!clientWidth && !clientHeight) {
            return emptyRect;
        }

        var styles = getWindowOf(target).getComputedStyle(target);
        var paddings = getPaddings(styles);
        var horizPad = paddings.left + paddings.right;
        var vertPad = paddings.top + paddings.bottom;

        // Computed styles of width & height are being used because they are the
        // only dimensions available to JS that contain non-rounded values. It could
        // be possible to utilize the getBoundingClientRect if only it's data wasn't
        // affected by CSS transformations let alone paddings, borders and scroll bars.
        var width = toFloat(styles.width),
            height = toFloat(styles.height);

        // Width & height include paddings and borders when the 'border-box' box
        // model is applied (except for IE).
        if (styles.boxSizing === 'border-box') {
            // Following conditions are required to handle Internet Explorer which
            // doesn't include paddings and borders to computed CSS dimensions.
            //
            // We can say that if CSS dimensions + paddings are equal to the "client"
            // properties then it's either IE, and thus we don't need to subtract
            // anything, or an element merely doesn't have paddings/borders styles.
            if (Math.round(width + horizPad) !== clientWidth) {
                width -= getBordersSize(styles, 'left', 'right') + horizPad;
            }

            if (Math.round(height + vertPad) !== clientHeight) {
                height -= getBordersSize(styles, 'top', 'bottom') + vertPad;
            }
        }

        // Following steps can't be applied to the document's root element as its
        // client[Width/Height] properties represent viewport area of the window.
        // Besides, it's as well not necessary as the <html> itself neither has
        // rendered scroll bars nor it can be clipped.
        if (!isDocumentElement(target)) {
            // In some browsers (only in Firefox, actually) CSS width & height
            // include scroll bars size which can be removed at this step as scroll
            // bars are the only difference between rounded dimensions + paddings
            // and "client" properties, though that is not always true in Chrome.
            var vertScrollbar = Math.round(width + horizPad) - clientWidth;
            var horizScrollbar = Math.round(height + vertPad) - clientHeight;

            // Chrome has a rather weird rounding of "client" properties.
            // E.g. for an element with content width of 314.2px it sometimes gives
            // the client width of 315px and for the width of 314.7px it may give
            // 314px. And it doesn't happen all the time. So just ignore this delta
            // as a non-relevant.
            if (Math.abs(vertScrollbar) !== 1) {
                width -= vertScrollbar;
            }

            if (Math.abs(horizScrollbar) !== 1) {
                height -= horizScrollbar;
            }
        }

        return createRectInit(paddings.left, paddings.top, width, height);
    }

    /**
     * Checks whether provided element is an instance of the SVGGraphicsElement.
     *
     * @param {Element} target - Element to be checked.
     * @returns {boolean}
     */
    var isSVGGraphicsElement = function () {
        // Some browsers, namely IE and Edge, don't have the SVGGraphicsElement
        // interface.
        if (typeof SVGGraphicsElement !== 'undefined') {
            return function (target) {
                return target instanceof getWindowOf(target).SVGGraphicsElement;
            };
        }

        // If it's so, then check that element is at least an instance of the
        // SVGElement and that it has the "getBBox" method.
        // eslint-disable-next-line no-extra-parens
        return function (target) {
            return target instanceof getWindowOf(target).SVGElement && typeof target.getBBox === 'function';
        };
    }();

    /**
     * Checks whether provided element is a document element (<html>).
     *
     * @param {Element} target - Element to be checked.
     * @returns {boolean}
     */
    function isDocumentElement(target) {
        return target === getWindowOf(target).document.documentElement;
    }

    /**
     * Calculates an appropriate content rectangle for provided html or svg element.
     *
     * @param {Element} target - Element content rectangle of which needs to be calculated.
     * @returns {DOMRectInit}
     */
    function getContentRect(target) {
        if (!isBrowser) {
            return emptyRect;
        }

        if (isSVGGraphicsElement(target)) {
            return getSVGContentRect(target);
        }

        return getHTMLElementContentRect(target);
    }

    /**
     * Creates rectangle with an interface of the DOMRectReadOnly.
     * Spec: https://drafts.fxtf.org/geometry/#domrectreadonly
     *
     * @param {DOMRectInit} rectInit - Object with rectangle's x/y coordinates and dimensions.
     * @returns {DOMRectReadOnly}
     */
    function createReadOnlyRect(_ref2) {
        var x = _ref2.x,
            y = _ref2.y,
            width = _ref2.width,
            height = _ref2.height;

        // If DOMRectReadOnly is available use it as a prototype for the rectangle.
        var Constr = typeof DOMRectReadOnly !== 'undefined' ? DOMRectReadOnly : Object;
        var rect = Object.create(Constr.prototype);

        // Rectangle's properties are not writable and non-enumerable.
        defineConfigurable(rect, {
            x: x, y: y, width: width, height: height,
            top: y,
            right: x + width,
            bottom: height + y,
            left: x
        });

        return rect;
    }

    /**
     * Creates DOMRectInit object based on the provided dimensions and the x/y coordinates.
     * Spec: https://drafts.fxtf.org/geometry/#dictdef-domrectinit
     *
     * @param {number} x - X coordinate.
     * @param {number} y - Y coordinate.
     * @param {number} width - Rectangle's width.
     * @param {number} height - Rectangle's height.
     * @returns {DOMRectInit}
     */
    function createRectInit(x, y, width, height) {
        return { x: x, y: y, width: width, height: height };
    }

    /**
     * Class that is responsible for computations of the content rectangle of
     * provided DOM element and for keeping track of it's changes.
     */

    var ResizeObservation = function () {

        /**
         * Creates an instance of ResizeObservation.
         *
         * @param {Element} target - Element to be observed.
         */

        /**
         * Broadcasted height of content rectangle.
         *
         * @type {number}
         */

        /**
         * Reference to the observed element.
         *
         * @type {Element}
         */
        function ResizeObservation(target) {
            _classCallCheck(this, ResizeObservation);

            this.broadcastWidth = 0;
            this.broadcastHeight = 0;
            this.contentRect_ = createRectInit(0, 0, 0, 0);

            this.target = target;
        }

        /**
         * Updates content rectangle and tells whether it's width or height properties
         * have changed since the last broadcast.
         *
         * @returns {boolean}
         */

        /**
         * Reference to the last observed content rectangle.
         *
         * @private {DOMRectInit}
         */

        /**
         * Broadcasted width of content rectangle.
         *
         * @type {number}
         */


        _createClass(ResizeObservation, [{
            key: 'isActive',
            value: function isActive() {
                var rect = getContentRect(this.target);

                this.contentRect_ = rect;

                return rect.width !== this.broadcastWidth || rect.height !== this.broadcastHeight;
            }
        }, {
            key: 'broadcastRect',
            value: function broadcastRect() {
                var rect = this.contentRect_;

                this.broadcastWidth = rect.width;
                this.broadcastHeight = rect.height;

                return rect;
            }
        }]);

        return ResizeObservation;
    }();

    var ResizeObserverEntry =

    /**
     * Creates an instance of ResizeObserverEntry.
     *
     * @param {Element} target - Element that is being observed.
     * @param {DOMRectInit} rectInit - Data of the element's content rectangle.
     */

    /**
     * Element size of which has changed.
     * Spec: https://wicg.github.io/ResizeObserver/#dom-resizeobserverentry-target
     *
     * @readonly
     * @type {Element}
     */
    function ResizeObserverEntry(target, rectInit) {
        _classCallCheck(this, ResizeObserverEntry);

        var contentRect = createReadOnlyRect(rectInit);

        // According to the specification following properties are not writable
        // and are also not enumerable in the native implementation.
        //
        // Property accessors are not being used as they'd require to define a
        // private WeakMap storage which may cause memory leaks in browsers that
        // don't support this type of collections.
        defineConfigurable(this, { target: target, contentRect: contentRect });
    }

    /**
     * Element's content rectangle.
     * Spec: https://wicg.github.io/ResizeObserver/#dom-resizeobserverentry-contentrect
     *
     * @readonly
     * @type {DOMRectReadOnly}
     */
    ;

    var ResizeObserverSPI = function () {

        /**
         * Creates a new instance of ResizeObserver.
         *
         * @param {ResizeObserverCallback} callback - Callback function that is invoked
         *      when one of the observed elements changes it's content dimensions.
         * @param {ResizeObserverController} controller - Controller instance which
         *      is responsible for the updates of observer.
         * @param {ResizeObserver} callbackCtx - Reference to the public
         *      ResizeObserver instance which will be passed to callback function.
         */

        /**
         * Reference to the associated ResizeObserverController.
         *
         * @private {ResizeObserverController}
         */

        /**
         * Reference to the callback function.
         *
         * @private {ResizeObserverCallback}
         */
        function ResizeObserverSPI(callback, controller, callbackCtx) {
            _classCallCheck(this, ResizeObserverSPI);

            this.activeObservations_ = [];
            this.observations_ = new MapShim();

            if (typeof callback !== 'function') {
                throw new TypeError('The callback provided as parameter 1 is not a function.');
            }

            this.callback_ = callback;
            this.controller_ = controller;
            this.callbackCtx_ = callbackCtx;
        }

        /**
         * Starts observing provided element.
         *
         * @param {Element} target - Element to be observed.
         * @returns {void}
         */

        /**
         * Registry of the ResizeObservation instances.
         *
         * @private {Map<Element, ResizeObservation>}
         */

        /**
         * Public ResizeObserver instance which will be passed to the callback
         * function and used as a value of it's "this" binding.
         *
         * @private {ResizeObserver}
         */

        /**
         * Collection of resize observations that have detected changes in dimensions
         * of elements.
         *
         * @private {Array<ResizeObservation>}
         */


        _createClass(ResizeObserverSPI, [{
            key: 'observe',
            value: function observe(target) {
                if (!arguments.length) {
                    throw new TypeError('1 argument required, but only 0 present.');
                }

                // Do nothing if current environment doesn't have the Element interface.
                if (typeof Element === 'undefined' || !(Element instanceof Object)) {
                    return;
                }

                if (!(target instanceof getWindowOf(target).Element)) {
                    throw new TypeError('parameter 1 is not of type "Element".');
                }

                var observations = this.observations_;

                // Do nothing if element is already being observed.
                if (observations.has(target)) {
                    return;
                }

                observations.set(target, new ResizeObservation(target));

                this.controller_.addObserver(this);

                // Force the update of observations.
                this.controller_.refresh();
            }
        }, {
            key: 'unobserve',
            value: function unobserve(target) {
                if (!arguments.length) {
                    throw new TypeError('1 argument required, but only 0 present.');
                }

                // Do nothing if current environment doesn't have the Element interface.
                if (typeof Element === 'undefined' || !(Element instanceof Object)) {
                    return;
                }

                if (!(target instanceof getWindowOf(target).Element)) {
                    throw new TypeError('parameter 1 is not of type "Element".');
                }

                var observations = this.observations_;

                // Do nothing if element is not being observed.
                if (!observations.has(target)) {
                    return;
                }

                observations.delete(target);

                if (!observations.size) {
                    this.controller_.removeObserver(this);
                }
            }
        }, {
            key: 'disconnect',
            value: function disconnect() {
                this.clearActive();
                this.observations_.clear();
                this.controller_.removeObserver(this);
            }
        }, {
            key: 'gatherActive',
            value: function gatherActive() {
                var _this = this;

                this.clearActive();

                this.observations_.forEach(function (observation) {
                    if (observation.isActive()) {
                        _this.activeObservations_.push(observation);
                    }
                });
            }
        }, {
            key: 'broadcastActive',
            value: function broadcastActive() {
                // Do nothing if observer doesn't have active observations.
                if (!this.hasActive()) {
                    return;
                }

                var ctx = this.callbackCtx_;

                // Create ResizeObserverEntry instance for every active observation.
                var entries = this.activeObservations_.map(function (observation) {
                    return new ResizeObserverEntry(observation.target, observation.broadcastRect());
                });

                this.callback_.call(ctx, entries, ctx);
                this.clearActive();
            }
        }, {
            key: 'clearActive',
            value: function clearActive() {
                this.activeObservations_.splice(0);
            }
        }, {
            key: 'hasActive',
            value: function hasActive() {
                return this.activeObservations_.length > 0;
            }
        }]);

        return ResizeObserverSPI;
    }();

    // Registry of internal observers. If WeakMap is not available use current shim
    // for the Map collection as it has all required methods and because WeakMap
    // can't be fully polyfilled anyway.
    var observers = typeof WeakMap !== 'undefined' ? new WeakMap() : new MapShim();

    /**
     * ResizeObserver API. Encapsulates the ResizeObserver SPI implementation
     * exposing only those methods and properties that are defined in the spec.
     */

    var ResizeObserver =
    /**
     * Creates a new instance of ResizeObserver.
     *
     * @param {ResizeObserverCallback} callback - Callback that is invoked when
     *      dimensions of the observed elements change.
     */
    function ResizeObserver(callback) {
        _classCallCheck(this, ResizeObserver);

        if (!(this instanceof ResizeObserver)) {
            throw new TypeError('Cannot call a class as a function.');
        }
        if (!arguments.length) {
            throw new TypeError('1 argument required, but only 0 present.');
        }

        var controller = ResizeObserverController.getInstance();
        var observer = new ResizeObserverSPI(callback, controller, this);

        observers.set(this, observer);
    };

    // Expose public methods of ResizeObserver.
    ['observe', 'unobserve', 'disconnect'].forEach(function (method) {
        ResizeObserver.prototype[method] = function () {
            var _observers$get;

            return (_observers$get = observers.get(this))[method].apply(_observers$get, arguments);
        };
    });

    var index = function () {
        // Export existing implementation if available.
        if (typeof global$1.ResizeObserver !== 'undefined') {
            return global$1.ResizeObserver;
        }

        return ResizeObserver;
    }();

    exports.default = index;
});