define('ember-measure/utils/get-content-rect', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getContentRect;
  function getContentRect(node, types) {
    var calculations = {};

    if (types.indexOf('client') > -1) {
      calculations.client = {
        top: node.clientTop,
        left: node.clientLeft,
        width: node.clientWidth,
        height: node.clientHeight
      };
    }

    if (types.indexOf('offset') > -1) {
      calculations.offset = {
        top: node.offsetTop,
        left: node.offsetLeft,
        width: node.offsetWidth,
        height: node.offsetHeight
      };
    }

    if (types.indexOf('scroll') > -1) {
      calculations.scroll = {
        top: node.scrollTop,
        left: node.scrollLeft,
        width: node.scrollWidth,
        height: node.scrollHeight
      };
    }

    if (types.indexOf('bounds') > -1) {
      var rect = node.getBoundingClientRect();
      calculations.bounds = {
        top: rect.top,
        right: rect.right,
        bottom: rect.bottom,
        left: rect.left,
        width: rect.width,
        height: rect.height
      };
    }

    if (types.indexOf('margin') > -1) {
      var styles = getComputedStyle(node);
      calculations.margin = {
        top: parseInt(styles.marginTop),
        right: parseInt(styles.marginRight),
        bottom: parseInt(styles.marginBottom),
        left: parseInt(styles.marginLeft)
      };
    }

    return calculations;
  }
});