define('ember-primer/utils/curve-lookup', ['exports', 'd3-shape'], function (exports, _d3Shape) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = curveLookup;


  var curveFunctions = {
    'basis-open': _d3Shape.curveBasisOpen,
    'basis': _d3Shape.curveBasis,
    'bundle': _d3Shape.curveBundle,
    'cardinal-closed': _d3Shape.curveCardinalClosed,
    'cardinal-open': _d3Shape.curveCardinalOpen,
    'cardinal': _d3Shape.curveCardinal,
    'catmull-rom-closed': _d3Shape.curveCatmullRomClosed,
    'catmull-rom-open': _d3Shape.curveCatmullRomOpen,
    'catmull-rom': _d3Shape.curveCatmullRom,
    'linear-closed': _d3Shape.curveLinearClosed,
    'linear': _d3Shape.curveLinear,
    'monotone-x': _d3Shape.curveMonotoneX,
    'monotone-y': _d3Shape.curveMonotoneY,
    'natural': _d3Shape.curveNatural,
    'step': _d3Shape.curveStep,
    'step-after': _d3Shape.curveStepAfter,
    'step-before': _d3Shape.curveStepBefore
  };

  function curveLookup(curve) {
    return curveFunctions[curve];
  }
});