define('ember-primer/helpers/pair-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pairBy = pairBy;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  /**
   * @pairBy(params);
   *
   * Creates a pair of values from an array of objects.
   *
   * Usage:
   *
   *   (pair-by "timestamp" "value" someArrayOfObjects)
   *   // => [[1450345920000, 1885], [1450345920000, 1885], ...]
   *
   * @param  {Array[...keys, data]} params
   * @public
   * @return {Array[Array[2]]}
   */
  function pairBy() {
    var args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    var params = args.slice();
    (false && !(params.length >= 2) && Ember.assert('pair-by requires at least 2 arguments: key, data', params.length >= 2));

    var data = params.pop();
    (false && !(Ember.isArray(data)) && Ember.assert('last argument must be an array of objects', Ember.isArray(data)));

    var _params = _toArray(params),
        keys = _params.slice(0);

    return data.map(function (d) {
      return keys.reduce(function (acc, k, index) {
        acc[index] = d[k];
        return acc;
      }, []);
    });
  }

  exports.default = Ember.Helper.helper(pairBy);
});