define('ember-primer/utils/helpers', ['exports', '@ember/runtime/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.isFunction = undefined;
	var isFunction = exports.isFunction = function isFunction(thing) {
		return (0, _utils.typeOf)(thing) === 'function';
	};

	exports.default = {
		evaluateProp: function evaluateProp(prop, data, active) {
			return isFunction(prop) ? prop(data, active) : prop;
		},
		evaluateStyle: function evaluateStyle(style, data, active) {
			var _this = this;

			if (!style || !Object.keys(style).some(function (value) {
				return isFunction(style[value]);
			})) {
				return style;
			}
			return Object.keys(style).reduce(function (prev, curr) {
				prev[curr] = _this.evaluateProp(style[curr], data, active);
				return prev;
			}, {});
		}
	};
});