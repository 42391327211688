define('ember-primer/components/primer-scatter/component', ['exports', 'ember-primer/components/primer-scatter/template', 'd3-scale', 'd3-array', 'd3-shape'], function (exports, _template, _d3Scale, _d3Array, _d3Shape) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var symbols = {
    wye: _d3Shape.symbolWye,
    triangle: _d3Shape.symbolTriangle,
    star: _d3Shape.symbolStar,
    square: _d3Shape.symbolSquare,
    diamond: _d3Shape.symbolDiamond,
    cross: _d3Shape.symbolCross,
    circle: _d3Shape.symbolCircle
  };

  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'g',

    values: [],

    newValues: [],

    xScale: null,

    yScale: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var values = this.get('values').filter(function (d) {
        return d;
      });

      var _getProperties = this.getProperties('xScale', 'yScale'),
          xScale = _getProperties.xScale,
          yScale = _getProperties.yScale;

      var newValues = values.slice().map(function (d) {
        var sym = (0, _d3Shape.symbol)();
        sym.size(d.size * 4);
        sym.type(symbols[d.symbol]);

        return Object.assign({}, d, { symbol: sym(), x: xScale(d.x), y: yScale(d.y) });
      });

      this.set('newValues', newValues);
    }
  });
});