define("ember-primer/utils/unsorted-closest-search", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (needle, haystack) {
    var idx = 0;
    var size = haystack.length;
    var closestIdx = 0;
    var closest = haystack[0];
    var current = void 0;

    while (idx++ < size) {
      current = haystack[idx];
      if (abs(current - needle) < abs(closest - needle)) {
        closest = current;
        closestIdx = idx;
      }
    }

    return [closest, closestIdx];
  };

  var abs = Math.abs;
});