define('ember-primer/components/primer-cursor/component', ['exports', 'ember-primer/utils/find-closest-cursor-points', 'ember-primer/components/primer-cursor/template'], function (exports, _findClosestCursorPoints, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var idleCallback = function idleCallback(cb) {
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(cb);
    } else {
      Ember.run.debounce(cb, 16, false);
    }
  };

  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'g',

    classNames: ['Primer-Cursor'],

    xScale: null,

    yScale: null,

    values: [],

    /**
     * Represents the cursor object, which is part of the public API.
     *
     * @public
     * @type {Object}
     */
    cursor: {
      x: 0,
      y: 0,
      xValues: 0,
      yValues: [0]
    },

    /**
     * Indicates whether the cursor is visible on the chart
     * @protected
     * @type {Boolean}
     */
    isActive: false,

    /**
     * Indicates if the mouse is within the bounds of the chart
     * @protected
     * @type {Boolean}
     */
    hasMouse: false,

    _lastPosition: [],

    /**
     * Public API for setting the current position
     * @public
     * @type {Array}
     */
    position: [],

    /**
     * Indicates whether to display the latest result when the mouse isn't in the
     * chart bounds.
     *
     * @public
     * @type {Boolean}
     */
    showLatestWhenInactive: true,

    didInsertElement: function didInsertElement() {
      var svg = this.element.closest('svg');
      svg.addEventListener('mousemove', this._handleMouseMove.bind(this));
      svg.addEventListener('mouseleave', this._handleMouseLeave.bind(this));
      svg.addEventListener('mouseenter', this._handleMouseEnter.bind(this));

      Ember.run.next(this, this._handleMouseLeave);
    },
    willDestroyElement: function willDestroyElement() {
      var svg = this.element.closest('svg');
      svg.removeEventListener('mousemove', this._handleMouseMove);
      svg.removeEventListener('mouseleave', this._handleMouseLeave);
      svg.removeEventListener('mouseenter', this._handleMouseEnter);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var hasMouse = this.get('hasMouse');
      var values = this.get('values');

      var _ref = this.get('position') || [],
          _ref2 = _slicedToArray(_ref, 2),
          xNew = _ref2[0],
          yNew = _ref2[1];

      var _ref3 = this._lastPosition || [],
          _ref4 = _slicedToArray(_ref3, 2),
          xLast = _ref4[0],
          yLast = _ref4[1];

      var _getProperties = this.getProperties('xOffset', 'yOffset'),
          xOffset = _getProperties.xOffset,
          yOffset = _getProperties.yOffset;

      if ((xLast !== xNew || yLast !== yNew) && values.length > 2) {
        this._mouseMove({ offsetY: yNew + yOffset, offsetX: xNew + xOffset }, false);
      }
    },
    _handleMouseMove: function _handleMouseMove(event) {
      var _this = this;

      // if (this.get('hasMouse')) {
      // idleCallback(() => this._mouseMove(event))
      // } else {
      Ember.run(function () {
        return _this._mouseMove(event);
      });
      // }
    },
    _handleMouseLeave: function _handleMouseLeave() {
      var showLatestWhenInactive = this.get('showLatestWhenInactive');

      var _getProperties2 = this.getProperties('xOffset'),
          xOffset = _getProperties2.xOffset;

      if (showLatestWhenInactive) {
        var xScale = this.get('xScale');
        var values = this.get('values');

        if (values.length > 2) {
          var _ref5 = values[values.length - 1] || [],
              _ref6 = _slicedToArray(_ref5, 1),
              x1 = _ref6[0];

          this.setProperties({ isActive: true, hasMouse: false });
          this._mouseMove({ offsetY: 0, offsetX: xScale(x1) + xOffset }, true);
        }
      } else {
        this.setProperties({ isActive: false, hasMouse: false });
      }
    },
    _handleMouseEnter: function _handleMouseEnter() {
      this.setProperties({ isActive: true, hasMouse: true });
    },
    _mouseMove: function _mouseMove(event) {
      var trigger = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (this.isDestroyed || this.isDestroying) return;
      this.setProperties({ isActive: true, hasMouse: true });

      var y = event.offsetY,
          x = event.offsetX;

      var _getProperties3 = this.getProperties('xScale', 'yScale', 'values'),
          xScale = _getProperties3.xScale,
          yScale = _getProperties3.yScale,
          values = _getProperties3.values;

      var _getProperties4 = this.getProperties('xOffset', 'yOffset'),
          xOffset = _getProperties4.xOffset,
          yOffset = _getProperties4.yOffset;

      if (xScale) {
        var _closestPoint = (0, _findClosestCursorPoints.default)([x, y], [xOffset, yOffset], xScale, yScale, values),
            _closestPoint2 = _slicedToArray(_closestPoint, 3),
            _closestPoint2$ = _slicedToArray(_closestPoint2[0], 2),
            xPointer = _closestPoint2$[0],
            yPointer = _closestPoint2$[1],
            _closestPoint2$2 = _slicedToArray(_closestPoint2[1], 2),
            xValue = _closestPoint2$2[0],
            yValues = _closestPoint2$2[1],
            closestYIndex = _closestPoint2[2];

        var _ref7 = this._lastPosition || [0, 0],
            _ref8 = _slicedToArray(_ref7, 2),
            xLast = _ref8[0],
            yLast = _ref8[1];

        if (xLast !== xPointer || yLast !== yPointer) {
          this.setProperties({
            isActive: true,
            cursor: {
              x: xPointer,
              y: yPointer,
              xValue: xValue,
              yValues: yValues,
              closestYIndex: closestYIndex
            }
          });

          if (trigger) {
            this.sendAction('_change', [xValue, yValues], [xPointer, yPointer]);
          }

          this._lastPosition = [xPointer, yPointer];
        }
      }
    }
  });
});