define('ember-primer/components/primer/basic-container/component', ['exports', 'ember-primer/components/primer/basic-container/template', 'ember-primer/utils/transform-string'], function (exports, _template, _transformString) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'g',

    /**
     * Offset x
     * @public
     * @type {Number}
     */
    x: 0,

    /**
     * Offset Y
     * @public
     * @type {Number}
     */
    y: 0,

    /**
     * Additional transform to apply to the container tag
     *
     * @public
     * @type {String}
     */
    transform: null,

    _transform: Ember.computed('x', 'y', 'transform', {
      get: function get() {
        var _getProperties = this.getProperties('x', 'y', 'transform'),
            x = _getProperties.x,
            y = _getProperties.y,
            transform = _getProperties.transform;

        return (0, _transformString.default)(transform, { translate: [x, y] }).trim();
      }
    }),

    attributeBindings: ['_transform:transform']
  });
});