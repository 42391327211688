define('ember-primer/components/primer-label/component', ['exports', 'ember-primer/components/primer-label/template', 'ember-primer/utils/transform-string'], function (exports, _template, _transformString) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Label = Ember.Component.extend({
    layout: _template.default,

    tagName: 'text',

    classNames: ['Primer-Label'],

    /**
     * Title attribute for accessibility
     * @public
     * @type {String}
     */
    title: null,

    /**
     * Computed to indicate if title is present
     * @private
     * @type {Boolean}
     */
    hasTitle: Ember.computed.notEmpty('title'),

    /**
     * Desc value for accessibility
     * @public
     * @type {String}
     */
    desc: null,

    /**
     * Computed to indicate if desc is present
     * @private
     * @type {Boolean}
     */
    hasDesc: Ember.computed.notEmpty('desc'),

    /**
     * Label text
     * @public
     * @type {String}
     */
    text: ' ',

    /**
     * Offset x
     * @public
     * @type {Number}
     */
    x: 0,

    /**
     * Offset Y
     * @public
     * @type {Number}
     */
    y: 0,

    /**
     * Data offset X
     * @public
     * @type {Number}
     */
    dx: 0,

    /**
     * Data offset Y, used for emulating line height.
     * @public
     * @type {Number}
     */
    dy: 0,

    /**
     * Applies rotation to the label
     *
     * @public
     * @type {Number}
     */
    angle: 0,

    /**
     * Specifies the font-size, which is also used for calculating line height.
     *
     * @public
     * @type {Number}
     */
    fontSize: 12,

    textLength: null,

    /**
     * Applies the text anchor. Can be one of start, middle, end.
     *
     * @public
     * @type {String}
     */
    textAnchor: 'start',

    /**
     * Applies the certical anchor, which is calculated from font-size, line-height, and cap-height.
     *
     * @public
     * @type {String}
     */
    verticalAnchor: 'start',

    capHeight: 0.71,

    lineHeight: 1,

    transform: null,

    _transform: Ember.computed('angle', 'x', 'y', 'transform', {
      get: function get() {
        var _getProperties = this.getProperties('angle', 'x', 'y', 'transform'),
            angle = _getProperties.angle,
            x = _getProperties.x,
            y = _getProperties.y,
            transform = _getProperties.transform;

        var rotatePart = angle && { rotate: [angle, x, y] };
        return transform || angle ? (0, _transformString.default)(transform, rotatePart).trim() : undefined;
      }
    }),

    attributeBindings: ['_transform:transform', 'textAnchor:text-anchor', 'textLength:text-length', 'fontSize:font-size'],

    textSpans: Ember.computed('text', {
      get: function get() {
        var text = this.get('text');
        if (Array.isArray(text)) {
          return text;
        } else {
          return text.split('\n');
        }
      }
    }),

    spanDy: Ember.computed('y', 'dy', 'capHeight', 'lineHeight', {
      get: function get() {
        var lineHeight = this.get('lineHeight');
        var fontSize = this.get('fontSize');
        var dy = this.get('dy');

        var _get = this.get('textSpans'),
            length = _get.length;

        var capHeight = this.get('capHeight');
        var anchor = this.get('verticalAnchor') || 'middle';
        switch (anchor) {
          case 'end':
            return dy + (capHeight / 2 + (0.5 - length) * lineHeight) * fontSize;
          case 'middle':
            return dy + (capHeight / 2 + (0.5 - length / 2) * lineHeight) * fontSize;
          default:
            return dy + (capHeight / 2 + lineHeight / 2) * fontSize;
        }
      }
    })
  });

  Label.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Label;
});