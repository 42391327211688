define('ember-measure/mixins/with-content-rect', ['exports', 'resize-observer-polyfill', 'ember-measure/utils/get-content-rect'], function (exports, _resizeObserverPolyfill, _getContentRect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var types = ['client', 'offset', 'scroll', 'bounds', 'margin'];

  exports.default = Ember.Mixin.create({

    rectTypes: types,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.runloopAwareMeasure = function (entries) {
        Ember.run.join(_this, _this.measure, entries);
      };
    },


    /**
     * Called once per run loop when this element has resized
     * @param {DOMContentRect} The DOM content rect
     */
    didResize: function didResize() /* rect */{},


    /**
     * The coordinate Rect of this element
     * @type {DOMContentRect}
     */
    contentRect: null,

    didInsertElement: function didInsertElement() {
      var _super;

      (_super = this._super).apply.apply(_super, arguments);
      this._resizeObserver = new _resizeObserverPolyfill.default(this.runloopAwareMeasure);
      this._resizeObserver.observe(this.element);
      this.runloopAwareMeasure();
    },
    willDestroyElement: function willDestroyElement() {
      var _super2;

      (_super2 = this._super).apply.apply(_super2, arguments);
      this._resizeObserver.disconnect(this.element);
    },
    measure: function measure(entries) {
      if (!this.element) {
        return;
      }

      var contentRect = (0, _getContentRect.default)(this.element, this.rectTypes);

      if (entries) {
        contentRect.entry = entries[0].contentRect;
      }

      this.set('contentRect', contentRect);
      this.didResize(contentRect);
    }
  });
});