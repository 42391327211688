define('ember-app-shell/instance-initializer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  // copied from:
  // https://github.com/ember-fastboot/ember-cli-fastboot/blob/e05d1a70c3b700102d1a2259b2fafda464773848/app/instance-initializers/clear-double-boot.js
  function initialize(appInstance) {
    if (typeof FastBoot === 'undefined') {
      var originalDidCreateRootView = appInstance.didCreateRootView;

      appInstance.didCreateRootView = function () {
        var elements = document.querySelectorAll(appInstance.rootElement + ' .ember-view');
        for (var i = 0; i < elements.length; i++) {
          var element = elements[i];
          element.parentNode.removeChild(element);
        }

        originalDidCreateRootView.apply(appInstance, arguments);
      };
    }
  }

  exports.default = { name: 'ember-app-shell', initialize: initialize };
});