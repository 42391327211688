define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "account": {
      "api": {
        "example": {
          "title": "Example request"
        },
        "help": {
          "message": "Visit the '<a href=\"https://docs.flood.io/\" target=\"_blank\" rel=\"noopener noreferrer\">'API reference docs'</a>' for more information on how to use the Flood API.",
          "title": "Help"
        },
        "regenerate": {
          "button": "Revoke and regenerate",
          "title": "Revoke and generate new token",
          "warning": "Generating a new token will revoke any previously issued tokens."
        },
        "title": "API Access",
        "token": {
          "hide": "Hide token",
          "reveal": "Reveal token",
          "title": "Flood API v2"
        }
      },
      "cloud-configuration": {
        "grid-hosting": {
          "add": "Add Credential",
          "aws": {
            "add": "Add new credentials",
            "form": {
              "name": {
                "label": "Description",
                "placeholder": "Enter description"
              },
              "role-arn": {
                "label": "Role ARN",
                "placeholder": "Enter Role ARN"
              },
              "role-external-id": {
                "label": "Role External ID",
                "placeholder": "Enter Role External ID"
              }
            },
            "table": {
              "actions": "Actions",
              "description": "Description",
              "role-arn": "AWS Role ARN"
            },
            "title": "Amazon Web Services"
          },
          "azure": {
            "add": "Add new credentials",
            "form": {
              "clientId": {
                "label": "Azure App ID",
                "placeholder": "Enter App ID"
              },
              "clientSecret": {
                "label": "Azure Password",
                "placeholder": "Enter password"
              },
              "name": {
                "label": "Description",
                "placeholder": "Enter description"
              },
              "subscriptionId": {
                "label": "Azure Subscription",
                "placeholder": "Enter subscription"
              },
              "tenantId": {
                "label": "Azure Tenant ID",
                "placeholder": "Enter Tenant ID"
              }
            },
            "table": {
              "actions": "Actions",
              "description": "Description",
              "tenantId": "Tenant ID"
            },
            "title": "Microsoft Azure"
          },
          "deletion": {
            "button": "Delete",
            "description": "Deleting this credential will disable the ability to use it to host grid nodes on this. Are you sure?",
            "title": "Delete Credential"
          },
          "gcp": {
            "add": "Add new credentials",
            "form": {
              "action": {
                "cancel": "Cancel",
                "changeKey": "Change key",
                "save": "Save",
                "upload": "Upload your Google Cloud service account key (JSON file) to quickly set up the integration with Google Cloud Platform."
              },
              "name": {
                "label": "Description",
                "placeholder": "Enter description"
              },
              "privateKeyId": {
                "label": "Private Key ID"
              },
              "projectId": {
                "label": "Project ID"
              }
            },
            "table": {
              "actions": "Actions",
              "description": "Description",
              "private-key-id": "Private Key ID",
              "project-id": "Project ID"
            },
            "title": "Google Cloud Platform",
            "validate": {
              "jsonFile": {
                "incorrectFormat": "The file '{fileName}' you uploaded doesn’t seem to have the correct format. Please check and try again.",
                "invalidUploadedKey": "The key you uploaded doesn’t work. Please check and try again.",
                "notExists": "Service Account Key must be present"
              }
            }
          },
          "hint": "'<a href=\"https://guides.flood.io/infrastructure/host-your-own-infrastructure\" rel=\"noopener noreferrer\" target=\"_blank\">'Learn More'</a>' about launching grids under your cloud-provider accounts.",
          "modal-title": {
            "create": "Save Credential",
            "edit": "Save Changes"
          },
          "no-credentials": "No Credentials",
          "save": "Save Credential",
          "subtitle": "Choose to host your own grids in the cloud provider of your choice. We currently allow for AWS, Azure and GCP.",
          "title": "Host your own grids"
        },
        "storage": {
          "aws": {
            "form": {
              "role-arn": {
                "label": "Role ARN",
                "placeholder": "Enter Role ARN"
              },
              "role-external-id": {
                "label": "Role External ID",
                "placeholder": "Enter Role External ID"
              },
              "s3-bucket-name": {
                "label": "S3 Bucket Name",
                "placeholder": "Enter S3 Bucket Name"
              },
              "s3-bucket-region": {
                "label": "S3 Bucket Region",
                "placeholder": "Enter S3 Bucket Region"
              }
            }
          },
          "continue": "I understand, continue",
          "delete": "Delete credential",
          "delete-confirmation-button": "Delete credential",
          "delete-confirmation-message": "Are you sure you want to delete this credential? Unless you add it back exactly the same you will lose access to this data in Flood.",
          "delete-confirmation-title": "Confirm deletion",
          "guides-link": "Visit the guides to understand more.",
          "modal-primary-button": "Save Storage Credentials",
          "modal-title": "Change your storage credentials",
          "subtitle": "Choose where Flood Files, Archives, Logs, Screenshots, and Network Traces live.",
          "title": "Store your Floods' data",
          "warning": "'<strong>'Warning:'</strong>' Changing these settings can cause data loss."
        }
      },
      "usage": {
        "actions": {
          "download-csv": "Download CSV"
        },
        "filter": {
          "project": {
            "placeholder": "Filter by project"
          }
        },
        "history": {
          "title": "Usage history"
        },
        "no-data": {
          "account": "No Floods ran during this billing period.",
          "project": "No Floods ran in {project} during this billing period."
        },
        "show-more": "Show More",
        "summary": {
          "vu": {
            "allowance": "Your tests can run up to {included, number} concurrent Virtual Users. '<button class=\"anchor\" data-open-intercom>'Talk to our sales team'</button>' if you need to test with more users.'</p>'",
            "subtitle": "{start, date, long}–{end, date, long}",
            "title": "Current period usage"
          },
          "vuh": {
            "columns": {
              "description": "Description",
              "quantity": "Quantity ('<abbr title=\"Virtual User Hours\">'VUH'</abbr>')"
            },
            "rows": {
              "included": "Included",
              "remaining": "Remaining",
              "used": "Used"
            },
            "subtitle": "{start, date, long}–{end, date, long}",
            "title": "Current period usage"
          },
          "vum": {
            "columns": {
              "amount": "Amount",
              "description": "Description",
              "quantity": "Quantity ('<abbr title=\"Virtual User Hours\">'VUH'</abbr>')",
              "total": "Current total"
            },
            "empty": "No usage has been recorded during this billing period.",
            "subtitle": "{start, date, long}–{end, date, long}",
            "title": "Current period usage"
          }
        },
        "vum": {
          "derivation": "{users} users for {minutes} minutes",
          "hours": "{hours} '<abbr title=\"Virtual User Hours\">'VUH'</abbr>'"
        }
      },
      "user": {
        "password": {
          "buttonLabel": "Change password",
          "currentUser": "You are currently signed in as '<b>'{name} &lt;{email}&gt;'</b>'.",
          "resetEmailSentMessage": "A password reset email has been sent to '<b>'{email}'</b>'.",
          "title": "Change your password"
        },
        "personalDetails": {
          "buttonLabel": "Update personal details",
          "confirmUpdatingEmail": "If {email} doesn't exist in our system, we will send a confirmation email to that address. Please check your mailbox.",
          "savingLabel": "Saving...",
          "title": "Update your personal details"
        }
      }
    },
    "actions": {
      "streams": {
        "create": "Create Stream"
      }
    },
    "ap-northeast-1": "Japan East (Tokyo)",
    "ap-northeast-2": "Korea Central (Seoul)",
    "ap-south-1": "India West (Mumbai)",
    "ap-southeast-1": "Southeast Asia (Singapore)",
    "ap-southeast-2": "Australia East (Sydney)",
    "australiaeast": "Australia East (Sydney)",
    "australiasoutheast": "Australia Southeast (Melbourne)",
    "aws": "Amazon Web Services",
    "azure": "Microsoft Azure",
    "banners": {
      "admin": {
        "action": "Sign out",
        "message": "You’re signed in as '<b>'{email}'</b>'. Any actions you take will happen on their account."
      },
      "problem": {
        "message": "There is a problem with your account.",
        "remedy": {
          "billing": "Please '<button class=\"anchor\" data-link-to-billing>'update your billing details'</button>', or '<button class=\"anchor\" data-open-intercom>'contact support'</button>' for more information.",
          "support": "Please '<button class=\"anchor\" data-open-intercom>'contact support'</button>' for more information."
        }
      }
    },
    "base-url": "Base URL",
    "billing": {
      "cancellation": {
        "buttonLabel": "Cancel Plan",
        "title": "Cancel your plan",
        "warningMessage": "Your account payments will be canceled and you will no longer be able to launch Floods."
      },
      "contactSales": {
        "contactSalesLink": "Talk to our sales team",
        "message": "if you have any questions or wish to make changes to your plan.",
        "trialMessage": "if you have any questions, or enter your payment details below to start a paid plan.",
        "trialMessageUsageEnd": "to start a paid plan.",
        "trialMessageUsageLink": "enter your payment details",
        "trialMessageUsageStart": "if you have any questions, or"
      },
      "coupon": {
        "complimentary": "Your account is '<b>'FREE'</b>' with our compliments! Enjoy!",
        "default": "Your account has a special coupon giving you '<b>'{discount}'</b>' off!",
        "invoiced": "You currently have an '<b>'invoiced account'</b>'.",
        "trial": "You are on a '<b>'FREE TRIAL'</b>' which ends on {periodEnd, date, long}."
      },
      "currentSubscription": {
        "cancelled": {
          "billingChanges": "Starting 9 January 2020, pricing will move to a simpler post-paid, usage-based pricing system. '<a href=\"https://guides.flood.io/pricing/how-much-does-it-cost\" target=\"_blank\" rel=\"noopener noreferrer\">'Find out more'</a>' about how it works.",
          "demand": "Your demand subscription will end on {periodEnd, date, long}. You may continue using your remaining {remainingHours, plural, one {# node hour} other {# node hours}} until then.",
          "hosted": "Your hosted subscription will end on {periodEnd, date, long}. You may continue using your {nodes, plural, one {# node} other {# nodes}} until then.",
          "legacy": "Your legacy subscriptions '<strong>'will not renew'</strong>' after the current billing period. To continue testing, opt-in to our new pricing system by updating your payment details below."
        },
        "title": "Current plan",
        "vum": {
          "billing-period": "Your current billing period will end on {periodEnd, date, long}.",
          "postPaid": "You are currently on our post-paid, usage-based pricing plan.",
          "prePaid": "You are currently on our pre-paid, usage-based pricing plan.",
          "stored-card": "Metered usage will be charged to the card ending in {cardLast4}",
          "viewUsageButton": "View usage"
        }
      },
      "invoices": {
        "columns": {
          "amount": "Amount",
          "download": "Download",
          "period": "Period",
          "quantity": "Quantity",
          "reason": "Reason",
          "status": "Status"
        },
        "rows": {
          "download": "PDF",
          "period": "{start, date, medium}–{end, date, medium}",
          "reason": {
            "manual": "Manual invoice",
            "subscription": "Billing cycle ended",
            "subscription_cycle": "Billing cycle ended",
            "subscription_threshold": "Threshold reached",
            "subscription_update": "Subsciption updated"
          }
        }
      },
      "paymentDetails": {
        "accountInformation": {
          "call-to-action": "Update Account Details",
          "title": "Account details"
        },
        "add": {
          "call-to-action": "Add Payment Details",
          "title": "Update your billing details"
        },
        "paymentMethod": {
          "title": "Payment details"
        },
        "update": {
          "call-to-action": "Update Payment Details",
          "title": "Update your billing details"
        }
      },
      "previousInvoices": {
        "empty": "You haven’t been invoiced yet.",
        "title": "Previous invoices"
      }
    },
    "brand": {
      "name": {
        "long": "Tricentis Flood",
        "short": "Flood"
      }
    },
    "brazilsouth": "South America (Brazil)",
    "browsers.java-selenium-chrome": "Google Chrome",
    "browsers.java-selenium-firefox": "Firefox",
    "ca-central-1": "Canada Central",
    "canadacentral": "Canada Central (Toronto)",
    "canadaeast": "Canada East (Quebec)",
    "cards.american-express": "American Express",
    "cards.amex": "American Express",
    "cards.discover": "Discover",
    "cards.mastercard": "MasterCard",
    "cards.visa": "Visa",
    "centralindia": "Central India (Pune)",
    "centralus": "US Central (Iowa)",
    "components": {
      "confirmUpdateEmail": {
        "acceptsTerms": "Accept Tricentis Flood's '<a href=\"https://www.tricentis.com/legal-information/contracts/\" rel=\"noopener noreferrer\" target=\"_blank\">'terms of service'</a>' and '<a href=\"https://www.tricentis.com/legal-information/contracts/\" target=\"_blank\" rel=\"noopener noreferrer\">'privacy policy'</a>'",
        "additionalMessage": "After clicking on the CONFIRM button, you will be logged out and redirected to the login page. Please use your new email and current password to log in again.",
        "confirm": "Confirm",
        "description": "Please confirm this change by entering your current password",
        "form": {
          "placeholder": {
            "password": "Password"
          }
        },
        "receiveUpdates": "Send occasional updates from Flood to my new email",
        "title": "Confirm email change"
      },
      "invalidLink": "The link is invalid or you have used it before. Please '<a href=\"/\">'click here'</a>' or wait {time} seconds to be redirected to home page.",
      "mulCreatingAndSelecting": {
        "alreadyExist": "'{value}' already exists in selected list",
        "duplicatedItemWasRemoved": "The duplicates of following {itemName} have been automatically removed:",
        "items": "items"
      }
    },
    "cpu.usageSystem": "System",
    "cpu.usageTotal": "Total",
    "cpu.usageUser": "User",
    "credit-card": {
      "details": "Card details",
      "email": "Send receipt to",
      "generic-error": "There is an issue with card details. Please review or try another one",
      "name": "Cardholder name",
      "startEditingCard": "Edit",
      "stopEditingCard": "Cancel",
      "submit": "Save"
    },
    "dangerZone": {
      "title": "Danger Zone"
    },
    "eastasia": "East Asia (Hong Kong)",
    "eastus": "US East (Virginia)",
    "eastus2": "US East (Virginia)",
    "errors": {
      "unrecoverable": "It looks like we had an issue loading some data for this page. Please reload this page or try going back."
    },
    "eu-central-1": "EU Central (Frankfurt)",
    "eu-west-1": "EU West (Ireland)",
    "eu-west-2": "UK South (London)",
    "eu-west-3": "EU West (Paris)",
    "explorer": {
      "controls": {
        "flood": {
          "tabs": {
            "issues": "Issues",
            "labels": "Labels",
            "notes": "Notes",
            "slos": "SLOs",
            "summary": "Summary"
          }
        },
        "floodNumber": "Flood #{number}",
        "grids": {
          "placeholder": "Select grid"
        },
        "logs": "Logs",
        "measurements": {
          "placeholder": {
            "all": "All measurements ({count})",
            "none": "Choose measurements&hellip;",
            "some": "Measurements ({count})"
          }
        },
        "relativeTimeWindows": {
          "12-months": "12 months",
          "24-hours": "24 hours",
          "30-days": "30 days",
          "6-months": "6 months",
          "60-days": "60 days",
          "7-days": "7 days",
          "90-days": "90 days",
          "placeholder": "Select time window",
          "project": "Entire Project"
        },
        "timeline": "Timeline"
      },
      "flood": {
        "tooltip": {
          "cannotModify": "Your team was assigned to this project with Read permission, therefore you cannot delete or start more like this flood"
        }
      },
      "issue": {
        "alerts": {
          "none": {
            "description": "Our automated algorithms analyzed the test results and found no issues.",
            "message": "No performance issues detected"
          },
          "running": {
            "description": "Our automated algorithms are analyzing the test results in real-time and will present any performance problems here as they are detected.",
            "message": "Performing analysis"
          }
        },
        "category": {
          "flood": {
            "bottleneck": {
              "concurrent_users_increasing_and_response_time_increasing": "Concurrent users are increasing while while response times are increasing. This pattern may indicate some form of bottleneck or queue from load in your target system.",
              "concurrent_users_level_and_response_time_increasing": "Concurrent users are steady but response times are increasing. This pattern may indicate some form of bottleneck or queue from load in your target system.",
              "title": "Performance Issue"
            },
            "error_rate": {
              "failed_transactions_over_threshold": "Failing transactions make up a large portion of transactions over this period.",
              "title": "Performance Issue"
            }
          },
          "grid": {
            "health": {
              "cpu_high": "Grid CPU is higher than 80%. This may impact the accuracy of your results due to high resource contention on the load generator.",
              "title": "Load Generation Issue"
            }
          }
        },
        "summary": {
          "results": "This test ran for {duration, plural, =0 {less than a minute} =1 {1 minute} other {{duration} minutes}}, and the average response time of the system under test was {responseTime}, with {failureRate, plural, =0 {no failures} other {a failure rate of {failureRate}%}} while reaching a concurrency of {concurrency} users."
        }
      },
      "notes": {
        "content": {
          "default": "Leave your notes to let others know more about this flood."
        },
        "controller": {
          "add": "Add",
          "done": "Done",
          "edit": "Edit"
        }
      },
      "project": {
        "awaiting-data": "We’re waiting for data from your first flood. Start a flood in this project if you haven’t already.",
        "awaiting-data-new-flood": "Start a flood"
      }
    },
    "explorer.measurements.names.concurrency": "Concurrency",
    "explorer.measurements.names.failed": "Failed Rate",
    "explorer.measurements.names.passed": "Passed Rate",
    "explorer.measurements.names.responseTime": "Response Time",
    "explorer.measurements.names.throughput": "Throughput",
    "explorer.measurements.names.transactionRate": "Transaction Rate",
    "explorer.measurements.units.bps": "",
    "explorer.measurements.units.count": "count",
    "explorer.measurements.units.ms": "ms",
    "explorer.measurements.units.rpm": "rpm",
    "explorer.measurements.units.rps": "rps",
    "explorer.measurements.units.users": "users",
    "extensionForm": {
      "button": "continue",
      "errors": {
        "empty": "You don’t have any projects, please create one to continue.",
        "permission": "You don’t have permission to create or modify streams in any projects. Please ask your Account Owner to assign you to a team with at least Write permission on a project."
      },
      "fields": {
        "file": {
          "label": "Name of the script to upload"
        },
        "projects": {
          "placeholder": "Select a project"
        },
        "radios": {
          "create": "Create a new stream",
          "modify": "modify an existing stream"
        },
        "streams": {
          "placeholder": "Select a stream"
        }
      },
      "label": "Please select a project in which you want to upload this recorded script"
    },
    "features.floods": "Floods",
    "features.grids": "Grids",
    "features.insights": "Insights",
    "features.test-plan": "Stream",
    "features.test-plans": "Streams",
    "flood-ui": {
      "dropdowns": {
        "toggle": {
          "placeholder": {
            "all": "All {name} ({count})",
            "none": "Choose {name}&hellip;",
            "some": "{name} ({count})"
          }
        }
      }
    },
    "flood.status-checks.flood-started": "Flood started",
    "flood.status-checks.flood-started-pending": "Flood queued...",
    "flood.status-checks.grids-started": "Grids started",
    "flood.status-checks.grids-started-pending": "Grids starting...",
    "flood.status-checks.receiving-data": "Receiving data",
    "flood.status-checks.receiving-data-pending": "Waiting for first data points...",
    "flood.status.archiving": "Archiving...",
    "flood.status.deleted": "Deleted",
    "flood.status.failed": "Failed",
    "flood.status.finished": "Completed",
    "flood.status.problem": "Problem",
    "flood.status.queued": "Queued...",
    "flood.status.running": "Running...",
    "flood.status.stopped": "Stopped",
    "flowdock": "Flowdock Chat",
    "fludui.button.download.loading": "Starting download&hellip;",
    "francecentral": "France Central (Paris)",
    "francesouth": "France South (Marseille)",
    "fri": "Friday",
    "gcp": "Google Cloud Platform",
    "grid": {
      "gridStructure": {
        "form": {
          "account": {
            "label": "Account",
            "placeholder": "Select an account..."
          },
          "infrastructure": {
            "hosted": "Hosted",
            "label": "Infrastructure",
            "standalone": {
              "label": "Standalone",
              "note": "Please checkout '<a href=\"https://bare-metal.flood.io//\" target=\"_blank\" rel=\"noopener noreferrer\">'Flood Bare Metal Agent'</a>' to learn how to launch a new Standalone Grid."
            }
          },
          "instancePricing": {
            "description": "Spot Pricing disabled, '<a href=\"https://aws.amazon.com/ec2/pricing/\" rel=\"noopener noreferrer\" target=\"_blank\">'regular EC2 Linux pricing'</a>' for this region applies.",
            "label": "Instance Pricing",
            "prefix": "USD $",
            "spotPricing": {
              "description": "Priced in cents per hour, per node.",
              "label": "Use Spot Pricing"
            }
          },
          "location": {
            "label": "Location"
          },
          "nodeType": {
            "label": "Node Type",
            "placeholder": "Select a size..."
          },
          "region": {
            "hint": "Pick a region to generate load from which matches the network conditions of your test customers.",
            "label": "Region",
            "placeholder": "Select a region..."
          },
          "size": {
            "label": "Size"
          },
          "zone": {
            "label": "Zone",
            "placeholder": "Select a zone..."
          }
        },
        "instruction": "Select region and number of nodes",
        "secondaryInstruction": "{quantity, plural, =1 {{quantity} node} other {{quantity} nodes}} in {region}",
        "title": "Grid structure"
      }
    },
    "grid.infrastructure.demand": "On Demand",
    "grid.infrastructure.hosted": "Hosted",
    "grid.infrastructure.shared": "Shared",
    "grid.infrastructure.standalone": "Standalone",
    "grid.node-status.healthy": "Running",
    "grid.node-status.pending": "Pending...",
    "grid.node-status.problem": "Problem",
    "grid.node-status.running": "Running",
    "grid.node-status.shutting-down": "Shutting Down...",
    "grid.node-status.started": "Started",
    "grid.node-status.starting": "Starting...",
    "grid.node-status.stopped": "Stoppped",
    "grid.node-status.stopping": "Stopping...",
    "grid.node-status.terminated": "Terminated",
    "grid.node-status.unhealthy": "Pending...",
    "grid.status-checks.auto_scaling_group": "Auto Scaling",
    "grid.status-checks.launch_configuration": "Launch Configuration",
    "grid.status-checks.load_balancer": "Load Balancer",
    "grid.status-checks.nodes_started": "Grid Nodes",
    "grid.status-checks.security_group": "Security Group",
    "grid.status.created": "Creating",
    "grid.status.deleted": "Deleted",
    "grid.status.failed": "Failed",
    "grid.status.invoiced": "Invoiced",
    "grid.status.modifying": "Modifying",
    "grid.status.queued": "Queued",
    "grid.status.restarting": "Restarting",
    "grid.status.started": "Started",
    "grid.status.starting": "Starting",
    "grid.status.stopped": "Stopped",
    "grids.all": "All Regions",
    "grids.max-nodes": "Up to {maxInstanceQuantity} nodes",
    "header": {
      "aboutFlood": "About",
      "account": {
        "menu": {
          "items": {
            "api": "API Access",
            "billing": "Billing",
            "cloud-configuration": "Cloud Configuration",
            "integrations": "Integrations",
            "orgs": "Organizations",
            "personalDetails": "Personal Details",
            "signOut": "Sign Out",
            "usage": "Usage"
          },
          "toolTip": "Manage your account and settings"
        },
        "usage": {
          "empty": "…",
          "free": "Free Trial",
          "timeRemaining": "{time} hours left",
          "toolTip": "View account usage"
        }
      },
      "fullName": "Your full name",
      "notifications": {
        "toolTip": "See the latest changes we’ve made to Flood"
      },
      "project": {
        "menu": {
          "createButton": "New Project",
          "title": "Project"
        },
        "settings": {
          "toolTip": "Project settings"
        }
      },
      "publicTagline": "Painless load testing provided by Tricentis Flood",
      "qtest": {
        "toolTip": "Access your qTest apps"
      },
      "signInButton": "Sign in",
      "support": {
        "toolTip": "Live chat with our support team"
      },
      "terms": "I agree to Tricentis Flood's '<a href=\"https://www.tricentis.com/legal-information/contracts/\" target=\"_blank\">'terms of service'</a>', and '<a href=\"https://www.tricentis.com/legal-information/contracts/\" target=\"_blank\">'privacy policy'</a>'"
    },
    "infrastructure": {
      "gridNode": {
        "problem": "Problem",
        "title": {
          "actions": "Actions",
          "buildSha": "Build Sha",
          "container": "In Container",
          "docker": "Docker",
          "executable": "Executable",
          "ip": "IP",
          "name": "Name",
          "nodes": "Nodes",
          "platform": "Platform",
          "status": "Status",
          "version": "Version"
        }
      },
      "gridStatus": {
        "title": {
          "activeFloods": "Active Floods",
          "grid": "Grid",
          "hostingProvider": "Hosting Provider",
          "id": "Identifier",
          "instanceType": "Instance Type",
          "name": "Name",
          "status": "Status"
        }
      },
      "grids": {
        "extendButton": "Extend",
        "extendGridTitle": "Extend Grid duration",
        "infoButton": "Info",
        "launchButton": "LAUNCH GRID",
        "linkButton": "LINK",
        "nodesButton": "Nodes",
        "regionTitle": "Region",
        "stopButton": "Stop",
        "stopGridMsg": "Are you sure you want to stop this Grid?",
        "stoppingInLabel": "Stopping in",
        "title": "Grids",
        "unlinkButton": "UNLINK",
        "unscheduledLabel": "Not scheduled to stop",
        "uptimeLabel": "Uptime"
      },
      "launchGrid": {
        "customHostedInputs": {
          "dns": "DNS",
          "invalidDNSFormat": "{dns} is not a valid DNS address",
          "subnet": {
            "label": "Subnet",
            "placeholder": "Select a subnet..."
          },
          "title": "Hosting Configuration",
          "willUse": {
            "customerSubnet": "Flood will use the {name} subnet with the address prefix {ip}",
            "defaultSubnet": "Flood will create a default subnet with the address prefix 10.0.0.0/24"
          }
        }
      }
    },
    "insights.ask-a-question": "Ask a question",
    "insights.guide": "Usage guide",
    "insights.title": "Insights",
    "instance-sizes.Standard_D4_v3": "Standard_D4_v3 4vCPU + 16GiB RAM",
    "instance-sizes.c5-2xlarge": "c5.2xlarge - 8vCPU + 16GiB RAM",
    "instance-sizes.c5-4xlarge": "c5.4xlarge - 16vCPU + 32GiB RAM",
    "instance-sizes.c5-9xlarge": "c5.9xlarge - 36vCPU + 72GiB RAM",
    "instance-sizes.m3-2xlarge": "m3.2xlarge - 8vCPU + 30GiB RAM [EC2 Classic]",
    "instance-sizes.m3-xlarge": "m3.xlarge - 4vCPU + 15GiB RAM [EC2 Classic]",
    "instance-sizes.m4-10xlarge": "m4.10xlarge - 40vCPU + 160GiB RAM",
    "instance-sizes.m4-16xlarge": "m4.16xlarge - 64vCPU + 256GiB RAM",
    "instance-sizes.m4-2xlarge": "m4.2xlarge - 8vCPU + 32GiB RAM",
    "instance-sizes.m4-4xlarge": "m4.4xlarge - 16vCPU + 64GiB RAM",
    "instance-sizes.m4-xlarge": "m4.xlarge - 4vCPU + 16GiB RAM",
    "instance-sizes.m5-12xlarge": "m5.12xlarge - 48vCPU + 128iB RAM",
    "instance-sizes.m5-24xlarge": "m5.24xlarge - 96vCPU + 384GiB RAM",
    "instance-sizes.m5-2xlarge": "m5.2xlarge - 8vCPU + 32GiB RAM",
    "instance-sizes.m5-4xlarge": "m5.4xlarge - 16vCPU + 64GiB RAM",
    "instance-sizes.m5-xlarge": "m5.xlarge - 4vCPU + 16GiB RAM",
    "integrations": {
      "addCredential": "Add Credential",
      "appdynamics": {
        "addBtn": "Generate API key",
        "deletion": {
          "description": "Are you sure you want to delete this AppDynamics connection?",
          "title": "Delete AppDynamics connection"
        },
        "form": {
          "apiKey": {
            "label": "API Key",
            "placeholder": "Enter your API Key here"
          },
          "name": {
            "label": "Description",
            "placeholder": "Enter description"
          }
        },
        "hint": "Generating your AppDynamics API Token allows you to integrate result data into the AppDynamics report platform. '<a href=\"https://guides.flood.io/continuous-integration/apm-tools/appdynamics\" rel=\"noopener noreferrer\" target=\"_blank\">'Learn More'</a>'",
        "table": {
          "actions": "Actions",
          "apiKey": "API Key",
          "description": "Description"
        }
      },
      "aws": {
        "addBtn": "Add AWS account",
        "copySuccess": "Copied successfully!",
        "createRoleLink": "Quickly create AWS IAM role",
        "defaultS3": "(Default)",
        "defaultS3Tooltip": "There's already a default bucket for your AWS integration. If you want to change default bucket, uncheck that one first.",
        "deletion": {
          "description": "Deleting this AWS connection will disable the ability to host grid nodes and store test artifacts on this Amazon account. Are you sure?",
          "title": "Delete AWS connection"
        },
        "errors": {
          "s3": "Flood is not authorized to read & write data to your bucket. Check your policy again, or follow our '<a target=\"_blank\" href=\"http://guides.flood.io/\">'guide'</a>'.",
          "sts": "Flood was not granted enough permissions to perform normal operations on your AWS account. Check your policy again, or follow our '<a target=\"_blank\" href=\"http://guides.flood.io/\">'guide'</a>'."
        },
        "form": {
          "accessKey": {
            "label": "AWS Access Key",
            "placeholder": "AKIAJN2M•••••••"
          },
          "accountId": {
            "label": "AWS Account ID",
            "placeholder": "12345•••••••"
          },
          "awsExternalId": {
            "label": "AWS External ID",
            "placeholder": "Press below button to generate an External ID"
          },
          "awsRoleName": {
            "label": "AWS Role Name",
            "placeholder": "A role on your AWS account that we can assume"
          },
          "credentialCheckbox": {
            "programmaticKey": "Programmatic Key",
            "sts": "STS Credential"
          },
          "credentialTypeLabel": "Credential Type",
          "generateIdButton": "Generate new ID",
          "name": {
            "duplication": "Account Name already exists",
            "label": "Account Name",
            "placeholder": "A name you'll recognize in Flood"
          },
          "s3BucketName": {
            "label": "S3 Bucket Name",
            "placeholder": "Your S3 Bucket name here"
          },
          "s3BucketRegion": {
            "label": "S3 Bucket Region",
            "placeholder": "Select a region"
          },
          "s3Toggle": {
            "defaultLabel": "Use this as the default bucket",
            "useLabel": "Use custom S3 bucket"
          },
          "secretKey": {
            "label": "AWS Secret Key",
            "placeholder": "Secret key"
          },
          "stsHint": "When specifying accounts that can assume a role in your AWS account, make sure to input Flood Account ID: "
        },
        "hint": "Adding your AWS account credentials allows you to launch Grid nodes under your own AWS account while continuing to use the Flood UI for reporting. '<a href=\"https://guides.flood.io/infrastructure/host-your-own-infrastructure/using-your-own-aws-account\" rel=\"noopener noreferrer\" target=\"_blank\">'Learn more'</a>'",
        "table": {
          "accountId": "AWS Account ID",
          "actions": "Actions",
          "description": "Description"
        }
      },
      "azure": {
        "addBtn": "Add Azure account",
        "deletion": {
          "description": "Are you sure you want to delete this Azure connection?",
          "title": "Delete Azure connection"
        },
        "form": {
          "clientId": {
            "label": "Azure App ID",
            "placeholder": "Enter App ID"
          },
          "clientSecret": {
            "label": "Azure Password",
            "placeholder": "Enter password"
          },
          "name": {
            "label": "Description",
            "placeholder": "Enter description"
          },
          "subscriptionId": {
            "label": "Azure Subscription",
            "placeholder": "Enter subscription"
          },
          "tenantId": {
            "label": "Azure Tenant ID",
            "placeholder": "Enter Tenant ID"
          }
        },
        "hint": " Adding your Azure account allows you to launch Grid nodes under your own Azure account while continuing to use the Flood UI for reporting. '<a href=\"https://guides.flood.io/infrastructure/host-your-own-infrastructure/using-your-own-azure-account\" rel=\"noopener noreferrer\" target=\"_blank\">'Learn More'</a>'",
        "table": {
          "actions": "Actions",
          "description": "Description",
          "tenantId": "Tenant ID"
        }
      },
      "datadog": {
        "addBtn": "Add API Key",
        "deletion": {
          "description": "Are you sure you want to delete this Datadog connection?",
          "title": "Delete Datadog connection"
        },
        "form": {
          "apiKey": {
            "label": "API Key",
            "placeholder": "Enter your API Key here"
          },
          "name": {
            "label": "Description",
            "placeholder": "Enter description"
          }
        },
        "hint": "Adding your Datadog API key allows you to integrate result data into the Datadog report platform. '<a href=\"https://guides.flood.io/\" rel=\"noopener noreferrer\" target=\"_blan\">'Learn More'</a>'",
        "table": {
          "actions": "Actions",
          "apiKey": "API Key",
          "description": "Description"
        }
      },
      "dynatrace": {
        "addBtn": "Add API token",
        "deletion": {
          "description": "Are you sure you want to delete this Dynatrace connection?",
          "title": "Delete Dynatrace connection"
        },
        "form": {
          "apiKey": {
            "label": "API Token",
            "placeholder": "Enter your API Token here"
          },
          "environmentId": {
            "label": "Environment ID",
            "placeholder": "Enter environment ID"
          },
          "name": {
            "label": "Description",
            "placeholder": "Enter description"
          }
        },
        "hint": "Adding your Dynatrace environment ID and API Token allows you to integrate result data into the Dynatrace report platform. '<a href=\"https://guides.flood.io/continuous-integration/apm-tools/dynatrace\" rel=\"noopener noreferrer\" target=\"_blan\">'Learn More'</a>'",
        "table": {
          "actions": "Actions",
          "apiToken": "API Token",
          "description": "Description",
          "environmentId": "Environment ID"
        }
      },
      "editCredential": "Edit Credential",
      "gcp": {
        "addBtn": "Add Service Account Key",
        "deletion": {
          "description": "Are you sure you want to delete this Google Cloud connection?",
          "title": "Delete Google Cloud connection"
        },
        "form": {
          "action": {
            "cancel": "Cancel",
            "changeKey": "Change key",
            "save": "Save",
            "upload": "Upload your Google Cloud service account key (JSON file) to quickly set up the integration with Google Cloud Platform."
          },
          "name": {
            "label": "Account Name",
            "placeholder": "A name you'll recognise on Flood"
          },
          "privateKeyId": {
            "label": "Private Key ID"
          },
          "projectId": {
            "label": "Project ID"
          }
        },
        "hint": " Adding your Google Cloud service account key allows you to launch Grid nodes under your own Google Cloud account while continuing to use the Flood UI for reporting. '<a href=\"https://guides.flood.io/infrastructure/host-your-own-infrastructure/using-your-own-google-cloud-account\" rel=\"noopener noreferrer\" target=\"_blank\">'Learn More'</a>'",
        "table": {
          "actions": "Actions",
          "description": "Description (Account Name)",
          "privateKeyId": "Private Key ID",
          "projectId": "Project ID"
        },
        "validate": {
          "jsonFile": {
            "incorrectFormat": "The file '{fileName}' you uploaded doesn’t seem to have the correct format. Please check and try again.",
            "invalidUploadedKey": "The key you uploaded doesn’t work. Please check and try again.",
            "notExists": "Service Account Key must be present"
          }
        }
      },
      "gh": {
        "addTrigger": "Add trigger"
      },
      "newrelic": {
        "addBtn": "Add license key",
        "deletion": {
          "description": "Are you sure you want to delete this NewRelic connection?",
          "title": "Delete NewRelic connection"
        },
        "form": {
          "apiKey": {
            "label": "License Key",
            "placeholder": "Enter your license key here"
          },
          "name": {
            "label": "Description",
            "placeholder": "Enter description"
          }
        },
        "hint": "Adding your New Relic license key allows you to integrate result data into the New Relic report platform. '<a href=\"https://guides.flood.io/continuous-integration/apm-tools/newrelic\" rel=\"noopener noreferrer\" target=\"_blank\">'Learn More'</a>'",
        "table": {
          "actions": "Actions",
          "description": "Description",
          "licenseKey": "License Key"
        }
      },
      "qtest": {
        "userConnected": "Connected to"
      },
      "samlp": {
        "addBtn": "Add SAML Connection",
        "connections": "{count, plural, =0 {Not connected} =1 {1 connection} other {{count} connections}}",
        "deletion": {
          "deleteSyncedUsers": "Delete members synced from this SAML connection",
          "description": "Deleting this SAML connection will make people from your organization no longer be able to log in to Flood using their SAML accounts. Are you sure?",
          "title": "Delete SAML connection"
        },
        "form": {
          "assignToOrg": {
            "label": "Assign users to the below organization",
            "tooltip": "Every time a user from your organization logs in to Flood using their SAML account, they will be auto-added to the organization on Flood that you select here."
          },
          "assignToTeam": {
            "label": "Assign users to the below team",
            "tooltip": "Every time a user from your organization logs in to Flood using their SAML account, they will be auto-added to the team on Flood that you select here."
          },
          "certificate": {
            "btnText": "Upload Certificate",
            "label": "X509 Signing Certificate",
            "noFile": "No file selected",
            "tooltip": "SAMLP server public key encoded in PEM or CER format"
          },
          "idPDomain": {
            "label": "IdP Domains",
            "placeholder": "contoso.com, fabrikam.com",
            "tooltip": "Comma-separated list of the domains that can be authenticated in the Identity Provider. Only needed for Identifier First authentication flows."
          },
          "name": {
            "label": "Connection Name",
            "tooltip": "This is the logical identifier of the connection. Connection name cannot be changed after saved."
          },
          "protocolBinding": {
            "label": "Protocol Binding"
          },
          "requestAlgorithm": {
            "label": "Sign Request Algorithm"
          },
          "requestAlgorithmDigest": {
            "label": "Sign Request Algorithm Digest"
          },
          "signInURL": {
            "label": "Sign In URL",
            "placeholder": "https://samlp.example.com/login"
          },
          "signOutURL": {
            "label": "Sign Out URL",
            "placeholder": "https://samlp.example.com/logout"
          },
          "signRequest": {
            "label": "Sign Request",
            "tooltip": "When enabled, the SAML authentication request will be signed. Download the '<a href=\"https://tricentis-flood.auth0.com/pem?cert=connection&_ga=2.269087345.1435683853.1590460464-195308697.1590042637/\" rel=\"noopener noreferrer\" target=\"_blank\">'certificate'</a>' and give it to SAMLP that will receive the signed assertion so it can validate the signature."
          }
        },
        "hint": "Adding your SAML connection allows members of your organization to log in to Flood using their SAML accounts. '<a href=\"https://guides.flood.io/\" rel=\"noopener noreferrer\" target=\"_blan\">'Learn More'</a>'",
        "secondPopup": {
          "callbackURL": "Post-back URL (Assertion Consumer Service URL)",
          "description": "Copy and send the below information to your SAML IdP Administrator to configure the connection:",
          "done": "Done",
          "entityID": "Entity ID of the Service Provider",
          "title": "Set Up The Connection In Your SAML IdP"
        },
        "table": {
          "actions": "Actions",
          "apiKey": "API Key",
          "description": "Description"
        }
      },
      "waad": {
        "actions": "Actions",
        "addBtn": "Add Azure AD connection...",
        "alertMessage": "Adding your Azure AD connection allows members of your organization to log in to Flood using Azure AD account.",
        "appId": {
          "label": "App ID URL",
          "tooltip": "Optional: Set this setting if you want to use the /oauth/token endpoint."
        },
        "cancel": "Cancel",
        "clientId": {
          "label": "Client ID",
          "tooltip": "This value is displayed as the Application ID in Azure AD"
        },
        "clientSecret": {
          "label": "Client Secret",
          "tooltip": "This is the secret key that you created in Azure AD"
        },
        "connections": "{count, plural, =0 {Not connected} =1 {1 connection} other {{count} connections}}",
        "delete": {
          "button": "Delete",
          "deleteSyncedUsers": "Delete members synced from this Azure AD connection",
          "sentence1": "Deleting this Azure AD connection will make people from Azure AD no longer be able to log in to Flood using their Azure AD account. Are you sure?",
          "title": "Delete Azure AD connection"
        },
        "domainAliases": {
          "label": "Domain Aliases",
          "placeholder": "contoso.com, fabrikam.com",
          "tooltip": "Optional: Comma-separated list of domains registered as alias of the primary one."
        },
        "done": "Done",
        "enableSyncUser": {
          "label": "Auto-sync users for all teams in account",
          "tooltip": "If this feature is selected, every time a user from the Azure AD that was used to set up this connection use their corporate account to log in to Flood, they will be auto-added to the Flood team with the same name as their group on Azure AD. If this is unchecked, then all users from Azure AD will go to the default team of the default organization on Flood."
        },
        "formTitle": "Microsoft Azure AD connection",
        "identityApi": {
          "label": "Identity API",
          "option1": "Azure Active Directory (v1.0)",
          "option2": "Microsoft Identity Platform (v2.0)"
        },
        "learnMore": "Learn More",
        "name": {
          "label": "Connection Name",
          "tooltip": "This is the logical identifier of the connection. Connection name cannot be changed after saved."
        },
        "nameColumn": "Name",
        "result": {
          "sentence1": "We need permission to query users' profiles from your organization's directory.",
          "sentence2": "If you are administrator of your organization's Azure Active Directory, click the link below:",
          "sentence3": "If not, please copy this URL and send it to the administrator.",
          "title": "Activate Azure AD connection"
        },
        "save": "Save",
        "tenantDomain": {
          "label": "Microsoft Azure AD Domain",
          "placeholder": "your-azure-ad-domain.com"
        },
        "useCommonEndpoint": {
          "label": "Use common endpoint",
          "tooltip": "Use 'https://login.windows.net/common' instead of default endpoint (https://login.windows.net/'<your_domain>'). This is typically enabled if you're using this for a Multi-tenant application in Azure AD."
        },
        "waadProtocol": {
          "label": "Protocol",
          "option1": "ws-federation",
          "option2": "openid-connect"
        }
      }
    },
    "japaneast": "Japan East (Saitama)",
    "japanwest": "Japan West (Osaka)",
    "koreacentral": "Korea Central (Seoul)",
    "koreasouth": "Korea South (Busan)",
    "logViewer": {
      "selectGridMessage": "Please choose a single Grid to view logs."
    },
    "loginForm": {
      "signUpLink": "Don't have an account yet? Sign up here"
    },
    "mem.usedPercent": "of capacity",
    "modal.cancel": "Cancel",
    "mon": "Monday",
    "name": "Name",
    "net.bytesRecv": "received",
    "net.bytesSent": "sent",
    "new-relic": "NewRelic",
    "northcentralus": "US North Central (Illinois)",
    "northeurope": "EU West (Ireland)",
    "object.assertion": "Assertions",
    "object.error": "Errors",
    "object.network": "Network",
    "object.screenshot": "Screenshot",
    "object.singular.assertion": "assertion",
    "object.singular.error": "error",
    "orgs": {
      "accounts": {
        "allMembers": "All Members",
        "rolePrefix": {
          "account": "Account",
          "org": "Organization",
          "team": "Team"
        },
        "title": "{count, plural, =1 {Account} other {Accounts}}"
      },
      "emptyListInviteMessageLink": "Invite them",
      "emptyListInviteMessageSuffix": " to get started.",
      "emptyListMessageLink": "Create one",
      "emptyListMessagePrefix": "There are {pluralType} in this {parentType}{punctuation}",
      "emptyListMessageSuffix": {
        "requestToBeAssigned": " Ask Team Manager to create one, or ask Org Manager to assign projects to your team.",
        "withPermission": " to get started.",
        "withoutPermission": " or maybe you don't have permission to view them."
      },
      "members": {
        "childTeamMembersPanel": "{count, plural, =1 {1 child team member} other {# child team members}}",
        "count": "{count, plural, =0 {no members} =1 {# member} other {# members}}",
        "dangerModal": {
          "delete": {
            "fromAccount": {
              "primaryButton": "Delete",
              "question": "Are you sure? This will:",
              "statement1": "Delete {count, plural, =1 {this member} other {these members}} from the current account.",
              "statement2": "Remove {count, plural, =1 {this member’s} other {these members’}} access to all resources of the current account.",
              "title": "Delete {count, plural, =1 {Member} other {Members}} From Account"
            }
          },
          "remove": {
            "fromOrg": {
              "primaryButton": "Remove",
              "question": "Are you sure? This will:",
              "statement1": "Remove {count, plural, =1 {this member} other {these members}} from the current organization.",
              "statement2": "Remove {count, plural, =1 {this member’s} other {these members’}} access to all resources in the current organization.",
              "title": "Remove {count, plural, =1 {Member} other {Members}} From Organization"
            },
            "fromTeam": {
              "primaryButton": "Remove",
              "question": "Are you sure? This will:",
              "statement1": "Remove {count, plural, =1 {this member} other {these members}} from the current team.",
              "statement2": "Remove {count, plural, =1 {this member’s} other {these members’}} access to all resources of the current team.",
              "title": "Remove {count, plural, =1 {Member} other {Members}} From Team"
            }
          }
        },
        "dangerZone": {
          "delete": {
            "fromAccount": {
              "button": "Delete",
              "description": "This will delete this member from the current account. '<strong>'This action cannot be undone. Please be certain.'</strong>'",
              "label": "Delete Member From Account"
            }
          },
          "remove": {
            "fromOrg": {
              "button": "Remove",
              "description": "This will remove this member from the current organization. '<strong>'Please be certain.'</strong>'",
              "label": "Remove Member From Organization"
            },
            "fromTeam": {
              "button": "Remove",
              "description": "This will remove this member from the current team. '<strong>'Please be certain.'</strong>'",
              "label": "Remove Member From Team"
            }
          },
          "title": "Danger Zone"
        },
        "editForm": {
          "changeBtn": "change",
          "currentTeam": "Current team",
          "email": {
            "label": "Email"
          },
          "infoMessage": {
            "invite": "Invite this member to '<strong>'{place}'</strong>' as:",
            "invited": "Invited this member to '<strong>'{place}'</strong>'."
          },
          "inviteTeam": {
            "submit": "Add to team"
          },
          "invitedRole": {
            "label": "Invited Role"
          },
          "name": {
            "label": "Member name"
          },
          "organization": "Organization",
          "resendBtn": "Resend",
          "resendInviteBtn": "Resend Invite",
          "resendInviteTeam": {
            "submit": "Resend Invite"
          },
          "role": {
            "label": "Role",
            "placeholder": "Select a role"
          },
          "sendBtn": "Add to team",
          "submit": {
            "label": "Save changes"
          },
          "title": "Edit user profile"
        },
        "inviteForm": {
          "confirmationMessage": {
            "multiple": "Invitation {count, plural, =1 {email} other {emails}} sent to {count, plural, =1 {# member} other {# members}}",
            "result": "If the {count, plural, =1 {{email} doesn't} other {# valid email addresses don't}} exist in another account, we will send {count, plural, =1 {an invitation to that email.} other {invitations to those emails.}}",
            "single": "An invitation email has been sent to {name} - {email}"
          },
          "email": {
            "label": "Email addresses",
            "placeholder": "Search by email address or name, or add a new user directly"
          },
          "errors": {
            "alreadyExists": " already exists in this {context}.",
            "bannedDomain": "Domain {domain} is banned from Flood due to violating our terms of service.",
            "emptyInvitationList": "Emails cannot be blank.",
            "emptyTeam": "Please select a team.",
            "invalidEmail": "{email} is not a valid email address."
          },
          "org": {
            "label": "Assign Organization Manager"
          },
          "role": {
            "label": "Select role",
            "placeholder": "Select a role"
          },
          "submit": {
            "label": "Invite {count, plural, =0 {member} =1 {# member} other {# members}}"
          },
          "team": {
            "invitationConfirm": {
              "diffAccountMsg": "You are currently signed-in to an account that is different from the account that you were invited. Please select your action.",
              "done": "Done",
              "expiredMsg": "The invitation link has expired or has been revoked. Contact your manager to generate a new link.",
              "hasAccountMsg": "Already have an account? Sign in here",
              "logOutAndSignUp": "Log out & sign up to join new account",
              "revealAction": "reveal",
              "revealLink": "Reveal Link",
              "revokeAction": "revoke",
              "revokeLink": "Revoke Link",
              "stayInAccount": "Stay in this account",
              "title": "Something went wrong"
            },
            "invitationLink": {
              "expiredMessage": "This link will expire on {expiredDate}",
              "invitationMessage": "We will send you a link to activate your account. Please check your mailbox.",
              "joinTeam": {
                "button": "Continue",
                "description": "'<strong>'{invitedBy}'</strong>' has invited you to '<strong>'{invitedTeam}'</strong>' on Flood.",
                "emailDescription": "Work email",
                "heading": "Join the team",
                "hint": "Enter your email and we'll send you a link to activate your account",
                "loginLink": "Already have an account?",
                "signIn": "Sign in",
                "signingIn": "signing in"
              },
              "message": "Share the link below to invite your colleagues to join this team as a",
              "requiredMail": "Email can't be blank",
              "retryLink": "retry sending again.",
              "retryMsg": "Didn’t receive anything? Please check your spam folder, or ",
              "signInBtn": " sign in",
              "title": "Invitation Link",
              "userExists": "This email has already been used or invited. If you own this email, check your mailbox or "
            },
            "label": "Add to team",
            "placeholder": "Select a team"
          },
          "title": "Invite members",
          "you": "(you)"
        },
        "membershipModal": {
          "primaryButton": "Done",
          "sub-title": "{user} holds membership in the following {count, plural, =1 {organization} other {organizations}}:",
          "title": "Membership details"
        },
        "newBtn": "Invite Members",
        "noChildTeamMembers": "There are no child team members.",
        "notFound": {
          "withPermission": "No results found. Please try a different search.",
          "withoutPermission": "No results matched your query, or you don't have permission to view full results."
        },
        "parentTeamMembersPanel": "{count, plural, =1 {1 member} other {# members}}",
        "searchHint": "Find a member in {context}...",
        "status": {
          "invited": "Invitation sent {date}",
          "lastSeen": "Last seen {date}",
          "neverSignedIn": "Never signed in",
          "notAMember": "Not a member of '<b>'{team}'</b>'"
        },
        "table": {
          "actions": {
            "deleteMembers": "Delete {count, plural, =1 {member} other {members}}",
            "removeFromOrg": "Remove from organization",
            "removeFromTeam": "Remove from team",
            "removeMembers": "Remove {count, plural, =1 {member} other {members}}",
            "revokeInvitations": "Revoke {count, plural, =1 {invitation} other {invitations}}"
          },
          "columns": {
            "email": "Email",
            "invitedMembership": "Invited Membership",
            "invitedRole": "Invited Role",
            "membership": "Membership",
            "name": "Name",
            "role": "Role",
            "status": "Status"
          },
          "labels": {
            "activeMembers": "{count, plural, =0 {0 active members} =1 {1 active member} other {# active members}}",
            "pendingInvitations": "{count, plural, =0 {0 pending invitations} =1 {1 pending invitation} other {# pending invitations}}"
          }
        },
        "title": "{count, plural, =1 {member} other {members}}"
      },
      "organizations": {
        "count": "{count, plural, =0 {no organizations} =1 {# organization} other {# organizations}}",
        "dangerModal": {
          "delete": {
            "primaryButton": "Delete",
            "question": "Are you sure? This will:",
            "statement1": "Delete all projects within this organization.",
            "statement2": "Delete all teams within this organization.",
            "statement3": "Remove all members from this organization.",
            "title": "Delete Organization"
          }
        },
        "dangerZone": {
          "delete": {
            "button": "Delete",
            "description": "This will delete this organization and all projects and teams within it. '<strong>'Please be certain.'</strong>'",
            "label": "Delete Organization"
          },
          "title": "Danger Zone"
        },
        "editBtn": "Edit Profile",
        "form": {
          "domain": {
            "hint": "If a user signs up using Google and the domain of the incoming email address matches the email domain defined here, then this user is assigned to the default team under this organization.",
            "label": "Email domain",
            "placeholder": "flood.io"
          },
          "email": {
            "label": "Support email",
            "placeholder": "support@flood.io"
          },
          "invitationErrorPrefix": "Invitation error:",
          "location": {
            "label": "Location",
            "placeholder": "Melbourne, Australia"
          },
          "name": {
            "label": "Organization name",
            "placeholder": "FloodIO"
          },
          "saveButton": {
            "create": "Create Organization",
            "inFlight": "Saving",
            "save": "Save changes"
          },
          "subTitle": "Organization details",
          "title": "Create a new organization",
          "website": {
            "label": "Website",
            "placeholder": "https://flood.io"
          }
        },
        "newBtn": "New Organization",
        "noOrganizations": {
          "description": "Please contact the Account Owner or Organization Managers to check if you have been invited to any organizations yet.",
          "title": "You don't belong to any Organizations."
        },
        "shortTitle": "{count, plural, =0 {No Orgs} =1 {# Org} other {# Orgs}}",
        "table": {
          "columns": {
            "members": "Members",
            "name": "Name",
            "projects": "Projects",
            "teams": "Teams"
          }
        },
        "title": "{count, plural, =1 {organization} other {organizations}}"
      },
      "projects": {
        "assignModal": {
          "assignTo": {
            "label": "Assign to",
            "placeholder": "Select a team"
          },
          "description": "The following {count, plural, =1 {project} other {# projects}} will be added to the team selected below:",
          "hints": {
            "alreadyAssigned": "Already assigned to {team} with {permission} permission",
            "cannotBeAssigned": "Cannot be assigned to {team} with {permission} permission",
            "overriding": "This will overwrite existing permissions (if any).",
            "permissionApplying": "This team and all of its child teams will have {permission} on selected {projectLength, plural, =1 {project} other {projects}}."
          },
          "saveButton": "Add projects to team",
          "title": "Add projects to team",
          "withPermission": {
            "label": "with permission",
            "placeholder": "Select a permission"
          }
        },
        "count": "{count, plural, =0 {no projects} =1 {# project} other {# projects}}",
        "dangerModal": {
          "delete": {
            "primaryButton": "Delete",
            "question": "Are you sure? This will:",
            "statement1": "Delete {count, plural, =1 {this project} other {these projects}}.",
            "statement2": "Delete all streams and floods within {count, plural, =1 {this project} other {these projects}}.",
            "title": "Delete {count, plural, =1 {Project} other {Projects}}"
          },
          "remove": {
            "primaryButton": "Remove",
            "question": "Are you sure? This will:",
            "statement1": "Remove {count, plural, =1 {this project} other {these projects}} from current team.",
            "statement2": "Remove the current team’s access to all resources in {count, plural, =1 {this project} other {these projects}}.",
            "title": "Remove {count, plural, =1 {Project} other {Projects}} From Team"
          }
        },
        "dangerZone": {
          "delete": {
            "button": "Delete",
            "description": "This will delete this project and all streams and floods within it. '<strong>'Please be certain.'</strong>'",
            "label": "Delete Project"
          },
          "remove": {
            "button": "Remove",
            "description": "This will remove this project from current team. '<strong>'Please be certain.'</strong>'",
            "label": "Remove Project From Team"
          },
          "title": "Danger Zone"
        },
        "editPermissionModal": {
          "description": "The following teams have been assigned to {projectName} project",
          "doneBtn": "Done",
          "otherTeams": "and {count} {count, plural, =1 {other team} other {other teams}}. (You don’t have permission to view)",
          "table": {
            "columns": {
              "name": "Name",
              "permission": "Permission"
            }
          },
          "title": "Teams assigned to {projectName}"
        },
        "form": {
          "name": {
            "label": "Project name",
            "placeholder": "Automation"
          },
          "organization": {
            "label": "Assign to organization",
            "placeholder": "Select"
          },
          "permission": {
            "description": "This team and all of its child teams will have '<b>'{permissionName}'</b>' permission on this project.",
            "label": "Permission of assigned team on this project",
            "loading": "Loading permission options...",
            "options": {
              "admin": "have full control over the project.",
              "read": "has read-only access to project resources.",
              "write": "can create, read, update and delete all project resources including streams, floods and grids. Cannot modify project name or delete project."
            },
            "placeholder": "Select"
          },
          "saveButton": {
            "create": "Create Project",
            "inFlight": "Saving",
            "save": "Save changes"
          },
          "subTitle": "Project details",
          "team": {
            "label": {
              "optional": "Assign to team (optional)",
              "required": "Assign to team"
            },
            "placeholder": "Select"
          },
          "title": "Create a new Project"
        },
        "newBtn": "New Project",
        "noProjects": {
          "description": {
            "contactAdmin": "Please contact the Organization Manager or Team Manager to check if there are any projects assigned to your team yet.",
            "createOne": "Fortunately, it’s very easy to create one."
          },
          "title": "You don’t have any projects."
        },
        "quickCreateProject": {
          "createButton": "Create Project",
          "placeholder": "Project Name"
        },
        "table": {
          "actions": {
            "assignToTeam": "Add to a team",
            "deleteProjects": "Delete {count, plural, =1 {project} other {projects}}",
            "removeProjects": "Remove {count, plural, =1 {project} other {projects}}"
          },
          "columns": {
            "assignedTo": "Assigned to",
            "name": "Name",
            "permission": "Permission"
          }
        },
        "title": "{count, plural, =1 {project} other {projects}}"
      },
      "settings": {
        "title": "settings"
      },
      "tables": {
        "counts": {
          "members": "'<span class=\"count-number\">'{count, plural, =0 {No} other {#}}'</span>' '<span class=\"count-label\">'{count, plural, =1 {Member} other {Members}}'</span>'",
          "projects": "'<span class=\"count-number\">'{count, plural, =0 {No} other {#}}'</span>' '<span class=\"count-label\">'{count, plural, =1 {Project} other {Projects}}'</span>'",
          "teams": "'<span class=\"count-number\">'{count, plural, =0 {No} other {#}}'</span>' '<span class=\"count-label\">'{count, plural, =1 {Team} other {Teams}}'</span>'"
        }
      },
      "teams": {
        "count": {
          "non-subteam": "{count, plural, =0 {no teams} =1 {# team} other {# teams}}",
          "subteam": "{count, plural, =0 {no sub-teams} =1 {# sub-team} other {# sub-teams}}"
        },
        "dangerModal": {
          "delete": {
            "primaryButton": "Delete",
            "question": "Are you sure? This will:",
            "statement1": "Delete {count, plural, =1 {this team} other {these teams}}.",
            "statement2": "Remove projects assigned to {count, plural, =1 {this team} other {these teams}}. Projects will still be part of the organization.",
            "statement3": "Remove all members from {count, plural, =1 {this team} other {these teams}}. Members will still be part of the organization.",
            "title": "Delete {count, plural, =1 {Team} other {Teams}}"
          }
        },
        "editBtn": "Edit Team",
        "form": {
          "assignManager": "Assign Team Manager",
          "autoSyncWaad": {
            "description": "Auto-sync users from the Azure AD group with the same name as this team",
            "tooltipOnDisabled": "This feature has been turned on for all teams by the Account Owner"
          },
          "description": {
            "label": "Description",
            "placeholder": "What is this team all about ?"
          },
          "name": {
            "label": "Team name",
            "placeholder": "FloodIO"
          },
          "parentTeam": {
            "label": {
              "optional": "Parent Team (Optional)",
              "require": "Parent Team"
            },
            "placeholder": "Select a parent team"
          },
          "saveButton": {
            "create": "Create Team",
            "inFlight": "Saving",
            "save": "Save changes"
          },
          "subTitle": "Team details",
          "title": "Create a new Team"
        },
        "newBtn": "New Team",
        "table": {
          "actions": {
            "deleteTeam": "Delete {count, plural, =1 {team} other {teams}}",
            "editTeam": "Edit {count, plural, =1 {team} other {teams}}"
          },
          "columns": {
            "members": "Members",
            "name": "Name",
            "projects": "Projects",
            "teams": "Teams"
          }
        },
        "title": "{count, plural, =1 {team} other {teams}}"
      },
      "title": "Organizations"
    },
    "pendingInvitations": {
      "emptyInvitationsMessage": "There are no pending invitations in this {context}.'<br>'Please be aware that invitations pending more than 7 days will be automatically cleaned up by our system.",
      "membershipModal": {
        "sub-title": "{email} has been invited to {count, plural, =1 {this organization} other {the following organizations}} as:",
        "title": "Invited Membership details"
      },
      "revoke": {
        "dangerModal": {
          "question": {
            "fromAccount": "Are you sure? This will revoke all pending invitations sent to this member from the current account.",
            "fromOrg": "Are you sure? This will revoke all pending invitations sent to this member from the current organization.",
            "fromTeam": "Are you sure? The invited member can no longer use this {count, plural, =1 {invitation} other {invitations}} to join the team.",
            "quickly": "Are you sure? This will revoke the selected pending {count, plural, =1 {invitation} other {invitations}}."
          },
          "title": {
            "fromAccount": "Revoke All Invitations",
            "fromOrg": "Revoke All Invitations From This Organization",
            "fromTeam": "Revoke {count, plural, =1 {Invitation} other {Invitations}}",
            "quickly": "Revoke {count, plural, =1 {Invitation} other {Invitations}}"
          }
        },
        "dangerZone": {
          "description": {
            "fromAccount": "'<p>'This will revoke all pending invitations sent to this member from the current account.'</p>''<p>''<strong>'This action cannot be undone. Please be certain.'</strong>''</p>'",
            "fromOrg": "'<p>'This will revoke all pending invitations sent to this member from the current organization.'</p>''<p>''<strong>'This action cannot be undone. Please be certain.'</strong>''</p>'",
            "fromTeam": "'<p>'The invited member can no longer use this invitation to join the team.'</p>''<p>''<strong>'This action cannot be undone. Please be certain.'</strong>''</p>'"
          },
          "label": {
            "fromAccount": "Revoke All Invitations",
            "fromOrg": "Revoke All Invitations From This Organization",
            "fromTeam": "Revoke This Invitation"
          }
        },
        "primaryButton": "Revoke"
      }
    },
    "permissions": {
      "grids": {
        "noAccess": {
          "billingLink": "Update your account",
          "messageEnd": "to start running tests.",
          "messageStart": "Your account is unable to launch Grids."
        }
      }
    },
    "plan.hyo_200h_month": "Hosted",
    "plan.payg": "Pay as you Go",
    "plan.pp_1000h_month": "Red Sea",
    "plan.pp_100h_month": "Pro",
    "plan.pp_100h_year": "100 Hours/month",
    "plan.pp_10h_year": "10 Hours/month",
    "plan.pp_150h_month": "Torrential Rain",
    "plan.pp_15h_month": "Standard",
    "plan.pp_200h_month": "King Tide",
    "plan.pp_200h_year": "200 Hours/month",
    "plan.pp_300h_month": "Atlantic",
    "plan.pp_400h_month": "Pacific",
    "plan.pp_50h_month": "Plus",
    "plan.pp_50h_year": "50 Hours/month",
    "plan.pp_600h_month": "Arctic",
    "plan.pp_800h_month": "North Sea",
    "plan.upgrade": {
      "demandGrids": "Demand grids are not available on your legacy plan",
      "fromLegacy": {
        "activate": "To activate your account, please",
        "learnMore": "to your account. '<a href=\"https://guides.flood.io/pricing/how-much-does-it-cost\" target=\"_blank\" rel=\"noopener noreferrer\">'Find out more'</a>' about Flood’s pricing and billing system.",
        "updatePaymentDetailsLink": "add your payment details"
      },
      "hostedGrids": "Hosted grids are not available on your legacy plan.",
      "integrations": "The following integrations are not available on your legacy plan.",
      "noHours": "You’ve used all the hours on your current legacy plan.",
      "organizations": "Multiple Teams and Organizations, Single Sign-On, Advanced Permission Management are not available on your legacy plan."
    },
    "product": {
      "demand": {
        "name": "Demand",
        "unit": "Node hours"
      },
      "hosted": {
        "name": "Hosted",
        "unit": "Nodes"
      }
    },
    "project": {
      "delete": {
        "message": "Are you sure you want to delete this Flood? This cannot be undone"
      },
      "sharing": {
        "closeModal": "Done",
        "copiedConfirmation": "copied ✓",
        "description": "Share this with your colleagues and they will be able to view this Flood, but not make changes to it.",
        "disableLabel": "Disable Sharing",
        "enableLabel": "Enable Secret Link",
        "title": "Sharing Settings",
        "urlLabel": "Secret Flood URL:"
      },
      "stop": {
        "message": "Are you sure you want to stop this Flood before it finishes?"
      }
    },
    "project.create": "Create New Project",
    "project.create.placeholder": "Project name",
    "project.delete": "Are you sure you want to delete this project? This is irreversible and will remove all associated Flood data.",
    "project.last-flooded": "Last flood: {date}",
    "qTestApplication": {
      "contactUs": "'<a href=\"https://www.qasymphony.com/contact-us/\" target=\"_blank\">'Contact us'</a>' today to see the benefits of these additional products.",
      "informationTooltip": {
        "actionRequired": "Action Required",
        "contactSupportAnalytics": "Analytics: Enterprise Custom Reporting for the Tricentis Platform - Please contact our customer support to gain access",
        "noLicense": "Your account does not have access to this product. '<a href=\"https://www.qasymphony.com/contact-us/\" target=\"_blank\">'Contact us'</a>' today to see the benefits of this product.",
        "noPermission": "Please ask your site administrator for access"
      },
      "introduceTooltip": {
        "analytics": "Analytics: Enterprise Custom Reporting for the Tricentis Platform",
        "explorer_sessions": "qTest Explorer: Exploratory and Session Based Testing Tool",
        "flood": "Flood: Intelligent Load Testing for DevOps",
        "insights": "qTest Insights: Actionable, Real-time Test Metrics",
        "launch": "qTest Launch: Centralized Test Automation",
        "manager": "qTest Manager: Agile Test Management",
        "parameters": "qTest Parameters: Test Data Management",
        "pulse": "qTest Pulse: Powering Agile and DevOps Workflows",
        "testproject": "TestProject: Easy Test Automation for All"
      },
      "ok": "OK"
    },
    "qtest": {
      "errors": {
        "NOT_FOUND": "Your verification link is invalid, please go to your qTest site and try again.",
        "QTEST_ERROR": "Your session has expired on qTest, please return to qTest and try launching Flood again.",
        "QTEST_PARAMS_MISSING": "qTest authentication failed, please try again from qTest.",
        "RECORD_INVALID": "{detail}",
        "SERVER_ERROR": "An error ocurred on our server while synchronizing your account, please contact support@flood.io",
        "UNAUTHORIZED": "Your current session does not match your account on qTest, please try again.",
        "default": "An error ocurred while synchronizing your account, please contact support@flood.io"
      },
      "verification": {
        "info": "Because this is the first time you log in to your Flood account from the qTest site '<b>'{domain}'</b>', we need to make sure it’s you. Please check the verification email that we have sent to '<b>'{email}'</b>' to proceed.",
        "success": "Your email has been successfully verified! Please click '<a href='\"{url}\" target=\"_blank\">here'</a>' to quickly navigate back to your qTest site and navigate back to Flood again."
      }
    },
    "role": {
      "admin": "Admin",
      "flooder": "Flooder",
      "guest": "Guest",
      "manager": "Manager",
      "member": "Member",
      "owner": "Owner"
    },
    "sa-east-1": "South America (Sao Paulo)",
    "sat": "Saturday",
    "short.ap-northeast-1": "Tokyo",
    "short.ap-northeast-2": "Seoul",
    "short.ap-south-1": "Mumbai",
    "short.ap-southeast-1": "Singapore",
    "short.ap-southeast-2": "Sydney",
    "short.australiaeast": "Australia East",
    "short.australiasoutheast": "Australia Southeast",
    "short.brazilsouth": "South America",
    "short.ca-central-1": "Canada",
    "short.canadacentral": "Canada Central",
    "short.canadaeast": "Canada East",
    "short.centralindia": "Central India",
    "short.centralus": "US Central",
    "short.eastasia": "East Asia",
    "short.eastus": "US East",
    "short.eastus2": "US East",
    "short.eu-central-1": "Frankfurt",
    "short.eu-west-1": "Ireland",
    "short.eu-west-2": "London",
    "short.eu-west-3": "Paris",
    "short.francecentral": "France Central",
    "short.francesouth": "France South",
    "short.japaneast": "Japan East",
    "short.japanwest": "Japan West",
    "short.koreacentral": "Korea Central",
    "short.koreasouth": "Korea South",
    "short.northcentralus": "US North Central",
    "short.northeurope": "EU North",
    "short.sa-east-1": "Sao Paulo",
    "short.southcentralus": "US South Central",
    "short.southeastasia": "Southeast Asia",
    "short.southindia": "South India",
    "short.uksouth": "UK South",
    "short.ukwest": "UK West",
    "short.us-east-1": "N. Virginia",
    "short.us-east-2": "Ohio",
    "short.us-west-1": "N. California",
    "short.us-west-2": "Oregon",
    "short.westcentralus": "US West Central",
    "short.westeurope": "EU West",
    "short.westindia": "West India",
    "short.westus": "US West",
    "short.westus2": "US West",
    "signUp": {
      "button": "Sign up",
      "description": "Sign up and start load testing with your first {allowance, number} virtual user hours free every month.",
      "emailDescription": "Work Email (you’ll use this to sign in)",
      "emailSentNotification": {
        "ifError": " if you think this is an error",
        "retry": "retry sending again.",
        "statement1": "If {email} is a valid email address and doesn’t exist in our system, we will send you an email containing a link to activate your account. Please check your mailbox.",
        "statement2": "Didn’t receive anything? Please check your spam folder, or ",
        "statement3": "Sorry, our systems have detected unusual traffic from your computer network and therefore you cannot sign up, please contact "
      },
      "heading": "Ready to get started?",
      "loginLink": "Already have an account?&nbsp'<a href=\"/login\">'Sign In'</a>'",
      "or": "OR",
      "withGithub": "Sign up with GitHub",
      "withGoogle": "Sign up with Google"
    },
    "slack": "Slack",
    "snackbar": {
      "actionButton": {
        "dismiss": "Dismiss"
      }
    },
    "southcentralus": "US South Central (Texas)",
    "southeastasia": "Southeast Asia (Singapore)",
    "southindia": "South India (Chennai)",
    "status-codes.0": "Non-HTTP Error",
    "status-codes.200": "200 OK",
    "status-codes.201": "201 Created",
    "status-codes.202": "202 Accepted",
    "status-codes.203": "203 Non-Authoritative Information",
    "status-codes.204": "204 No Content",
    "status-codes.205": "205 Reset Content",
    "status-codes.206": "206 Partial Content",
    "status-codes.207": "207 Multi-Status",
    "status-codes.208": "208 Already Reported",
    "status-codes.209": "226 IM Used",
    "status-codes.300": "300 Multiple Choices",
    "status-codes.301": "301 Moved Permanently",
    "status-codes.302": "302 Found",
    "status-codes.303": "303 See Other",
    "status-codes.304": "304 Not Modified",
    "status-codes.305": "305 Use Proxy",
    "status-codes.306": "306 Switch Proxy",
    "status-codes.307": "307 Temporary Redirect",
    "status-codes.308": "308 Permanent Redirect",
    "status-codes.400": "400 Bad Request",
    "status-codes.401": "401 Unauthorized",
    "status-codes.402": "402 Payment Required",
    "status-codes.403": "403 Forbidden",
    "status-codes.404": "404 Not Found",
    "status-codes.405": "405 Method Not Allowed",
    "status-codes.406": "406 Not Acceptable",
    "status-codes.407": "407 Proxy Authentication Required",
    "status-codes.408": "408 Request Timeout",
    "status-codes.409": "409 Conflict",
    "status-codes.410": "410 Gone",
    "status-codes.411": "411 Length Required",
    "status-codes.412": "412 Precondition Failed",
    "status-codes.413": "413 Payload Too Large",
    "status-codes.414": "414 URI Too Long",
    "status-codes.415": "415 Unsupported Media Type",
    "status-codes.416": "416 Range Not Satisfiable",
    "status-codes.417": "417 Expectation Failed",
    "status-codes.418": "418 I’m a teapot",
    "status-codes.421": "421 Misdirected Request",
    "status-codes.422": "422 Unprocessable Entity",
    "status-codes.423": "423 Locked",
    "status-codes.424": "424 Failed Dependency",
    "status-codes.426": "426 Upgrade Required",
    "status-codes.428": "428 Precondition Required",
    "status-codes.429": "429 Too Many Requests",
    "status-codes.431": "431 Request Header Fields Too Large",
    "status-codes.444": "No Response",
    "status-codes.451": "451 Unavailable For Legal Reasons",
    "status-codes.495": "495 SSL Certificate Error",
    "status-codes.496": "496 SSL Certificate Required",
    "status-codes.497": "497 HTTP Request Sent to HTTPS Port",
    "status-codes.499": "499 Client Closed Request",
    "status-codes.500": "500 Internal Server Error",
    "status-codes.501": "501 Not Implemented",
    "status-codes.502": "502 Bad Gateway",
    "status-codes.503": "503 Service Unavailable",
    "status-codes.504": "504 Gateway Timeout",
    "status-codes.505": "505 HTTP Version Not Supported",
    "status-codes.506": "506 Variant Also Negotiates",
    "status-codes.507": "507 Insufficient Storage",
    "status-codes.508": "508 Loop Detected",
    "status-codes.510": "510 Not Extended",
    "status-codes.511": "511 Network Authentication Required",
    "status-codes.520": "520 Unknown Error",
    "status-codes.521": "521 Web Server Is Down",
    "status-codes.522": "522 Connection Timed Out",
    "status-codes.523": "523 Origin Is Unreachable",
    "status-codes.524": "524 A Timeout Occurred",
    "status-codes.525": "525 SSL Handshake Failed",
    "status-codes.526": "526 Invalid SSL Certificate",
    "status-codes.Non HTTP response code: java.net.URISyntaxException": "Non-HTTP Error",
    "status-codes.java.net.UnknownHostException": "java.net.UnknownHostException",
    "status-codes.null": "Non-HTTP Error",
    "streamEditor": {
      "design": {
        "configureButton": "Configure Launch",
        "description": "Define your load test plan. '<a class=\"help-link\" href=\"https://guides.flood.io/overview-of-flood/designing-your-test\" target=\"_blank\" rel=\"noopener noreferrer\">'Need help getting started?'</a>'",
        "fields": {
          "addStep": {
            "label": "Add Step"
          },
          "host": {
            "hint": "Specify the protocol and host of the server you want to send traffic to. Don’t include the trailing path or any additional parameters, you’ll add these in the next step.",
            "label": "Protocol and Host",
            "title": "Base URL"
          },
          "name": {
            "label": "Test Name",
            "title": "Test Name"
          },
          "options": {
            "defaultToolName": "the load generation tool",
            "description": "Advanced parameters allow you to override arguments and flags sent to {tool} at runtime, these are entirely optional. '<a class=\"help-link\" href=\"https://guides.flood.io/test-execution/test-parameters\" target=\"_blank\" rel=\"noopener noreferrer\">'Learn More'</a>'.",
            "hint": "For JMeter use the -J prefix, otherwise use -D for Gatling",
            "label": "Optional Parameters",
            "title": "Advanced Parameters"
          },
          "steps": {
            "title": "Test Steps"
          },
          "testFiles": {
            "description": "Upload your test files, including any resources or plugins your tests rely on. They will be saved in '<a class=\"help-link\" href=\"https://guides.flood.io/scripting-and-tools/test-data\" target=\"_blank\" rel=\"noopener noreferrer\">'/data/flood/files'</a>' on each node.",
            "detectedTool": "We have detected that this is a '<b>'{tool}'</b>' test, but you can change the tool below if necessary.",
            "title": "Test Scripts"
          },
          "tool": {
            "browserSelectLabel": "Selenium Browser:",
            "browserSelectPlaceholder": "Select browser engine...",
            "help": "'<a class=\"help-link\" href=\"https://guides.flood.io/scripting-and-tools/choosing-a-tool\" target=\"_blank\" rel=\"noopener noreferrer\">'Which tool should I use?'</a>'",
            "title": "Tool Configuration"
          },
          "type": {
            "builder": {
              "description": "'<a class=\"help-link\" href=\"https://guides.flood.io/scripting-and-tools/test-builder\" target=\"_blank\" rel=\"noopener noreferrer\">'Use our online test builder'</a>' to quickly get started generating load against any HTTP endpoint.",
              "label": "Test Builder"
            },
            "title": "Test Type",
            "uploader": {
              "description": "Run your existing '<a class=\"help-link\" href=\"https://guides.flood.io/scripting-and-tools/flood-element\" target=\"_blank\" rel=\"noopener noreferrer\">'Element'</a>', '<a class=\"help-link\" href=\"https://guides.flood.io/scripting-and-tools/jmeter\" target=\"_blank\" rel=\"noopener noreferrer\">'JMeter'</a>', '<a class=\"help-link\" href=\"https://guides.flood.io/scripting-and-tools/gatling\" target=\"_blank\" rel=\"noopener noreferrer\">'Gatling'</a>', or '<a class=\"help-link\" href=\"https://guides.flood.io/scripting-and-tools/selenium\" target=\"_blank\" rel=\"noopener noreferrer\">'Selenium'</a>' scripts.",
              "label": "Script Upload"
            }
          }
        },
        "sloButton": "Configure Service Level Objectives",
        "title": "Design"
      },
      "header": {
        "deleteButton": "Delete",
        "lastSaved": "Changes saved {time}",
        "tabs": {
          "design": "Design",
          "launch": "Launch",
          "slos": "SLOs"
        },
        "title": {
          "default": "Stream Editor",
          "new": "Create Stream"
        },
        "tooltip": {
          "cannotModify": "Your team was assigned to this project with Read permission, therefore you cannot modify the streams and run the tests within this project"
        }
      },
      "launch": {
        "activateAccount": {
          "button": "Activate account",
          "marketing": {
            "contactUs": {
              "description": "if you’re having trouble.",
              "link": "talk to our support team"
            },
            "learnMore": {
              "description": "about Flood pricing, or",
              "link": "Learn more"
            },
            "message": "Continue to activate the Flood free tier and test with {allowance, number} '<abbr title=\"Virtual User Hours\">'VUH'</abbr>' for free every month. After that our simple post-paid, usage-based pricing allows you to keep testing as much as you need.",
            "title": "Get started with Flood"
          },
          "paymentDetails": "Payment details",
          "title": "Activate account"
        },
        "backButton": "Design",
        "description": "Select the number of users, region, and ramp up profile for this Flood.  '<a class=\"help-link\" href=\"https://guides.flood.io/overview-of-flood/launching-your-test\" target=\"_blank\" rel=\"noopener noreferrer\">'Need help getting started?'</a>'",
        "error": {
          "default": "An error occurred while launching, please try again."
        },
        "fields": {
          "duration": {
            "label": "Duration in minutes",
            "title": "Duration",
            "validations": {
              "lowerThanIncrement": "This test is metered in {increment} minute increments. You have selected a duration of '<b>'{wantedDuration}'</b>' minutes, but will be charged for '<b>'{actualDuration}'</b>' minutes."
            }
          },
          "grids": {
            "placeholder": "Select grids...",
            "title": "Grids"
          },
          "infrastructureType": {
            "demand": {
              "description": "Use Flood’s infrastructure to easily generate load for your tests.",
              "label": "Demand"
            },
            "hosted": {
              "description": "Use your own AWS/Azure/Google Cloud account to generate load for maximum flexibility.",
              "label": "Hosted"
            },
            "title": "Infrastructure Type"
          },
          "nodesPerRegion": {
            "label": "Nodes",
            "title": "Nodes per Region",
            "validations": {}
          },
          "rampUp": {
            "label": "Ramp Up in minutes",
            "title": "Ramp Up",
            "validations": {}
          },
          "regions": {
            "help": "'<a class=\"help-link\" href=\"https://guides.flood.io/infrastructure/demand-infrastructure/aws-regions-supported\" target=\"_blank\" rel=\"noopener noreferrer\">'Which regions do we support?'</a>'",
            "limitReached": "Demand tests are limited to {count, plural, one {# region} other {# regions}}.",
            "placeholder": "Select grid regions...",
            "title": "Regions"
          },
          "usersPerNode": {
            "label": "Users per Node",
            "title": "Users per Node",
            "validations": {}
          },
          "usersPerRegion": {
            "label": "Users",
            "title": "Users per Region",
            "validations": {}
          }
        },
        "launchButton": "Launch test",
        "summary": {
          "cannotEstimate": {
            "launchWithoutEstimateLabel": "I understand, launch anyway.",
            "message": "Your test plan does not appear to '<a class=\"help-link\" href=\"https://guides.flood.io/test-execution/test-parameters\" target=\"_blank\" rel=\"noopener noreferrer\">'use parameters'</a>' recognized by Flood for controlling concurrency, duration, and ramp up. You can still launch a flood '<a class=\"help-link\" href=\"https://guides.flood.io/pricing/why-dont-i-see-a-vuh-estimate\" target=\"_blank\" rel=\"noopener noreferrer\">'without this estimate'</a>'.",
            "title": "Unable to estimate total '<a class=\"help-link\" href=\"https://guides.flood.io/pricing/what-is-a-vuh\" target=\"_blank\" rel=\"noopener noreferrer\">''<abbr title=\"Virtual User Hours\">'VUH'</abbr>''</a>'."
          },
          "hasEstimate": {
            "demand": "This will simulate '<strong>'{estimatedTotalUsers, number}'</strong>' total {estimatedTotalUsers, plural, one {user} other {users}} across '<strong>'{regionCount, number}'</strong>' {regionCount, plural, one {region} other {regions}}. Estimated usage will be '<strong>'{estimatedVirtualUserHours, number}'</strong>' '<a class=\"help-link\" href=\"https://guides.flood.io/pricing/what-is-a-vuh\" target=\"_blank\" rel=\"noopener noreferrer\">''<abbr title=\"Virtual User Hours\">'VUH'</abbr>''</a>'.",
            "hosted": "This will simulate '<strong>'{estimatedTotalUsers, number}'</strong>' total {estimatedTotalUsers, plural, one {user} other {users}} across '<strong>'{regionCount, number}'</strong>' {regionCount, plural, one {grid} other {grids}}. Estimated usage will be '<strong>'{estimatedVirtualUserHours, number}'</strong>' '<a class=\"help-link\" href=\"https://guides.flood.io/pricing/what-is-a-vuh\" target=\"_blank\" rel=\"noopener noreferrer\">''<abbr title=\"Virtual User Hours\">'VUH'</abbr>''</a>'."
          },
          "title": "Summary"
        },
        "title": "Launch Flood"
      }
    },
    "streams": {
      "columns": {
        "concurrentUsers": "Concurrent Users (Planned)",
        "errorRate": "Error Rate",
        "lastFlooded": "Last Flooded",
        "name": "Test",
        "numberOfFloods": "Number of floods",
        "responseTime": "Response Time μ",
        "throughput": "Throughput",
        "transactionRate": "Transaction Rate"
      },
      "noFloods": "No Floods",
      "unnamedStream": "(Draft)"
    },
    "sun": "Sunday",
    "suspended": {
      "message": "Your account has been suspended. Please '<a href=\"https://flood.io/support\">'contact support'</a>'.",
      "title": "Account Suspended"
    },
    "taskCompletionSteps": {
      "authenticate": "Authenticating",
      "completeSync": "Complete",
      "syncProjects": "Sync Projects",
      "verifyAccount": "Verifying credentials"
    },
    "test-plan-step.path": "Request path",
    "test-plan-step.verb": "HTTP Method",
    "test-plans.streamType.builder": "Test Builder",
    "test-plans.streamType.uploader": "Upload test files",
    "test-plans.streamType.uploader:flood-chrome": "Flood Element",
    "thu": "Thursday",
    "tools.flood-chrome": "Flood Element",
    "tools.gatling": "Gatling",
    "tools.java-selenium-chrome": "Selenium (Chrome)",
    "tools.java-selenium-firefox": "Selenium (Firefox)",
    "tools.jmeter": "JMeter",
    "tools.ruby-jmeter": "Ruby JMeter",
    "tools.selenium": "Selenium",
    "tue": "Tuesday",
    "uksouth": "UK South (London)",
    "ukwest": "UK West (Cardiff)",
    "us-east-1": "US East (Virginia)",
    "us-east-2": "US East (Ohio)",
    "us-west-1": "US West (California)",
    "us-west-2": "US West (Oregon)",
    "wed": "Wednesday",
    "welcome": {
      "acceptsTerms": "Accept Tricentis Flood's '<a href=\"https://www.tricentis.com/legal-information/contracts/\" rel=\"noopener noreferrer\" target=\"_blank\">'terms of service'</a>' and '<a href=\"https://www.tricentis.com/legal-information/contracts/\" target=\"_blank\" rel=\"noopener noreferrer\">'privacy policy'</a>'",
      "description": "Thanks for signing up, let’s get to know each other:",
      "errors": {
        "auth0": "Password cannot contain any part of your full name, email or the first part of it."
      },
      "form": {
        "hints": {
          "email": "You'll use this to sign in",
          "socialSignUp": "You’ll use this to sign in, but '<strong>'{originalEmail}'</strong>' will still be your main email"
        },
        "placeholder": {
          "companyName": "Company name",
          "companySize": "Company size",
          "email": "Email",
          "fullName": "Full name",
          "password": "Password",
          "yourRole": "Your role"
        }
      },
      "invitation": {
        "description": {
          "inviteLinkTeam": "'<strong>'{userName}'</strong>' has invited you to '<strong>'{teamName}'</strong>' on Flood.",
          "organization": "'<strong>'{userName}'</strong>' has invited you to the '<strong>'{orgName}'</strong>' organzation on Flood as organization manager.",
          "team": "'<strong>'{userName}'</strong>' has invited you to '<strong>'{teamName}'</strong>' on Flood."
        },
        "expired": "The {type} link has expired or you have activated your account using this link. Please '<a href=\"/\">'click here'</a>' or wait {time} seconds to be redirected to login page.",
        "success": "You have successfully joint the team {teamName}. Please '<a href=\"/\">'click here'</a>' to return to home page or wait {time} seconds to be redirected automatically.",
        "title": "Join the team"
      },
      "receiveUpdates": "Receive occasional updates from Flood (no more than once a month)",
      "title": "Welcome!"
    },
    "westcentralus": "US West Central (Wyoming)",
    "westeurope": "EU West (Netherlands)",
    "westindia": "West India (Mumbai)",
    "westus": "US West (California)",
    "westus2": "US West (Washington)"
  }]];
  _exports.default = _default;
});