define('ember-code-block/components/code-block/component', ['exports', 'ember-code-block/components/code-block/template', 'highlight'], function (exports, _template, _highlight) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var CodeBlockComponent = Ember.Component.extend({
		layout: _template.default,
		tagName: 'pre',

		classNames: ['hljs'],

		tabReplace: ' ',
		classPrefix: null,

		code: null,

		highlightedCode: Ember.computed('code', {
			get: function get() {
				var code = this.get('code');
				var tabReplace = this.get('tabReplace');
				var classPrefix = this.get('classPrefix');

				var config = { tabReplace: tabReplace };

				if (classPrefix) {
					config.classPrefix = classPrefix;
				}

				if (Ember.isPresent(code)) {
					_highlight.default.configure(config);
					var result = _highlight.default.highlightAuto(code);
					return Ember.String.htmlSafe(result.value);
				} else {
					return '';
				}
			}
		})
	});

	CodeBlockComponent.reopenClass({
		positionalParams: ['code']
	});

	exports.default = CodeBlockComponent;
});