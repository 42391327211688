define('ember-primer/helpers/scale', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.scale = scale;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /**
   * Scale helper scales input data with the supplied scales for x and y.
   *
   * @param  {Array} data Pairs
   * @param  {Object} hash
   * @return {Array} Scaled pairs
   * @public
   */

  function scale(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        data = _ref2[0];

    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var xScale = hash.xScale,
        yScale = hash.yScale;
    (false && !(xScale) && Ember.assert('You must provide xScale', xScale));
    (false && !(yScale) && Ember.assert('You must provide yScale', yScale));


    if (data[0]) {
      var _data = _slicedToArray(data, 1),
          sample = _data[0];

      (false && !((sample[0] || sample[0] === 0) && (sample[1] || sample[1] === 0)) && Ember.assert('You must provide data in the format of [[x,y], [x,y], ...]', (sample[0] || sample[0] === 0) && (sample[1] || sample[1] === 0)));
    }

    return data.map(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
          x = _ref4[0],
          y = _ref4[1];

      return [xScale(x), yScale(y)];
    });
  }

  exports.default = Ember.Helper.helper(scale);
});