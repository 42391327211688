define('ember-primer/utils/transform-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = toTransformString;
  /**
   * Given an object with CSS/SVG transform definitions, return the string value
   * for use with the `transform` CSS property or SVG attribute. Note that we
   * can't always guarantee the order will match the author's intended order, so
   * authors should only use the object notation if they know that their transform
   * is commutative or that there is only one.
   *
   * Borrowed from Victory Charts
   *
   * @public
   * @param {Object} obj An object of transform definitions.
   * @returns {String} The generated transform string.
   */
  function toTransformString(obj) {
    for (var _len = arguments.length, more = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      more[_key - 1] = arguments[_key];
    }

    if (more.length > 0) {
      return more.reduce(function (memo, currentObj) {
        return [memo, toTransformString(currentObj)].join(' ');
      }, toTransformString(obj));
    } else {
      if (!obj || typeof obj === 'string') {
        return obj;
      }
      var transforms = [];
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          var value = obj[key];
          transforms.push(key + '(' + value + ')');
        }
      }
      return transforms.join(' ').trim();
    }
  }
});