define("ember-primer/utils/binary-closest-search", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = binaryClosestSearch;
  var abs = Math.abs;


  /**
   * A simple binary search for the closest element by value. Assumes haystack is
   * sorted.
   * @param  {Number} needle
   * @param  {Array<Number>} haystack
   * @return {Number} The index of the closest match in haystack
   * @public
   */
  function binaryClosestSearch(needle, haystack) {
    var haystackLength = haystack.length;
    var low = 0;
    var high = haystackLength - 1;
    var middle = void 0;
    while (low < high) {
      middle = low + high >>> 1;

      if (abs(haystack[middle] - needle) < abs(haystack[middle + 1] - needle)) {
        high = middle;
      } else {
        low = middle + 1;
      }
    }

    return low;
  }
});