define('ember-primer/components/primer-transition/component', ['exports', 'ember-primer/components/primer-transition/template', 'ember-primer/utils/timer', 'd3-ease', 'ember-primer/utils/interpolation'], function (exports, _template, _timer, _d3Ease, _interpolation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    /**
     * @public
     * Input values to be animated
     * @type {Array}
     */
    values: [],

    newValues: [],

    duration: 1550,

    ease: _d3Ease.easePolyOut,

    interpolator: null,

    queue: [],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.timer) {
        this.timer = new _timer.default();
      }
      var data = this.get('values').slice();
      var previousData = this.previousData;

      // console.debug('didUpdate');

      if (!previousData) {
        this.previousData = data.slice();
        this.set('newValues', this.previousData);
        return;
      }

      // if (this.previousData[0].x === data[0].x) {
      //   console.log('nothing changed');
      //   return;
      // }

      // cancel existing loop if it exists
      if (this.loopID) {
        this.timer.unsubscribe(this.loopID);
        this.loopID = null;
      }

      // Data is an array of values
      this.queue.push(data);

      // run.next(this, ()=>{
      //   this.previousData = data;
      // });

      /* Start traversing the tween queue */
      this.traverseQueue();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // run.next(this, () => {
      //   let data = this.get('values').slice();
      //   let { previousData } = this;
      //   console.debug('didInsertElement');

      //   if (!previousData) {
      //     this.previousData = data.slice();
      //     this.set('newValues', this.previousData);
      //     return;
      //   }
      // });
    },
    willDestroyElement: function willDestroyElement() {
      if (this.loopID) {
        this.timer.unsubscribe(this.loopID);
      }
    },
    traverseQueue: function traverseQueue() {
      var timer = this.timer;

      var duration = this.get('duration');

      if (this.queue.length && this.previousData) {
        var prevData = this.previousData;

        /* Get the next index */

        var _queue = _slicedToArray(this.queue, 1),
            data = _queue[0];
        /* compare cached version to next props */
        // console.log('traverseQueue', prevData[0], data[0]);

        this.interpolator = (0, _interpolation.victoryInterpolator)(prevData.slice(), data.slice());
        this.loopID = timer.subscribe(this.nextFrame.bind(this), duration);
      } else {
        // console.log('Stop timer');
        // timer.stop();
      }
    },
    nextFrame: function nextFrame(elapsed, duration) {
      var _this = this;

      var timer = this.timer,
          interpolator = this.interpolator;


      var step = duration ? elapsed / duration : 1;
      // console.log(duration, elapsed);

      // Animation is finished
      if (step >= 1) {
        Ember.run(this, function () {
          return _this.set('newValues', interpolator(1));
        });
        // this.set('newValues', interpolator(1));

        var loopID = this.loopID;

        if (loopID) {
          timer.unsubscribe(loopID);
          this.loopID = null;
        }

        this.previousData = this.queue.shift();
        // this.queue.shift();
        this.traverseQueue();

        return;
      }

      var ease = this.ease;

      var result = interpolator(ease(step));

      // console.log(step, elapsed);
      // console.log(result[0]);
      // this.set('newValues', result);
      Ember.run.sync(this, this.set('newValues', result.slice()));
    }
  });
});