define('ember-primer/utils/largest-triangle-three-bucket-sample', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var floor = Math.floor,
      abs = Math.abs;


  /**
   * Downsamples a dataset to threshold while maintaining the visual characteristics
   *
   * @param  {Array}  data      Array of number pairs [[x,y],[x,y]]
   * @param  {Number} threshold Downsample rate, typically a range between 0 and `data.length`.
   *                            A value of 0 disables sampling.
   *
   * @return {Array}            Array of downsampled values.
   * @public
   */
  function largestTriangleThreeBuckets(data, threshold) {
    var dataLength = data.length;
    if (threshold >= dataLength || threshold === 0) {
      return data; // Nothing to do
    }

    (false && !(dataLength > 0 && data[0][0] && data[0][1]) && Ember.assert('data should be an array of arrays representing [x,y] points in series', dataLength > 0 && data[0][0] && data[0][1]));


    var sampled = [];
    var sampledIndex = 0;

    // Bucket size. Leave room for start and end data points
    var every = (dataLength - 2) / (threshold - 2);

    var a = void 0,
        // Initially a is the first point in the triangle
    maxAreaPoint = void 0,
        maxArea = void 0,
        area = void 0,
        nextA = void 0;

    a = 0;

    sampled[sampledIndex++] = data[a]; // Always add the first point

    for (var i = 0; i < threshold - 2; i++) {

      // Calculate point average for next bucket (containing c)
      var avgX = 0;
      var avgY = 0;
      var avgRangeStart = floor((i + 1) * every) + 1;
      var avgRangeEnd = floor((i + 2) * every) + 1;
      avgRangeEnd = avgRangeEnd < dataLength ? avgRangeEnd : dataLength;

      var avgRangeLength = avgRangeEnd - avgRangeStart;

      for (; avgRangeStart < avgRangeEnd; avgRangeStart++) {
        avgX += data[avgRangeStart][0] * 1; // * 1 enforces Number (value may be Date)
        avgY += data[avgRangeStart][1] * 1;
      }

      avgX /= avgRangeLength;
      avgY /= avgRangeLength;

      // Get the range for this bucket
      var rangeOffs = floor((i + 0) * every) + 1;
      var rangeTo = floor((i + 1) * every) + 1;

      // Point a
      var pointAX = data[a][0] * 1; // enforce Number (value may be Date)
      var pointAY = data[a][1] * 1;

      maxArea = area = -1;

      for (; rangeOffs < rangeTo; rangeOffs++) {
        // Calculate triangle area over three buckets
        area = abs((pointAX - avgX) * (data[rangeOffs][1] - pointAY) - (pointAX - data[rangeOffs][0]) * (avgY - pointAY)) * 0.5;
        if (area > maxArea) {
          maxArea = area;
          maxAreaPoint = data[rangeOffs];
          nextA = rangeOffs; // Next a is this b
        }
      }

      sampled[sampledIndex++] = maxAreaPoint; // Pick this point from the bucket
      a = nextA; // This a is the next a (chosen b)
    }

    sampled[sampledIndex++] = data[dataLength - 1]; // Always add last

    return sampled;
  }

  exports.default = largestTriangleThreeBuckets;
});