define('ember-primer/components/primer-axis/component', ['exports', 'd3-axis', 'd3-selection'], function (exports, _d3Axis, _d3Selection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var AXIS_MAP = {
    top: _d3Axis.axisTop,
    right: _d3Axis.axisRight,
    bottom: _d3Axis.axisBottom,
    left: _d3Axis.axisLeft
  };

  function translateByOrientation(orientation, rect, offsetX, offsetY) {
    var _bottom$top$left$righ = _slicedToArray({
      'bottom': [0, rect.height],
      'top': [0, 0],
      'left': [0, 0],
      'right': [rect.width, 0]
    }[orientation], 2),
        x = _bottom$top$left$righ[0],
        y = _bottom$top$left$righ[1];

    return 'translate(' + (x + offsetX) + ',' + (y + offsetY) + ')';
  }

  var AxisComponent = Ember.Component.extend({
    tagName: 'g',

    classNames: ['Primer-Axis'],

    classNameBindings: ['orientation'],

    ticks: 10,

    /**
     * Represents the axis orientation. You should always declare this.
     *
     * @public
     * @type {String}
     */
    orientation: 'bottom',

    /**
     * A scaling function used for this axis.
     *
     * @public
     * @type {Function}
     */
    scale: null,

    /**
     * The format used for the ticks for this axis.
     * [See D3 docs for more details](https://github.com/d3/d3-axis#axis_tickFormat)
     *
     * @public
     * @type {Function}
     */
    tickFormat: null,

    /**
     * The inner tick size for the ticks for this axis.
     * [See D3 docs for more details](https://github.com/d3/d3-axis#axis_tickSizeInner)
     *
     * @public
     * @type {Number}
     */
    tickSizeInner: 4,

    /**
     * The outer tick size for the ticks for this axis.
     * [See D3 docs for more details](https://github.com/d3/d3-axis#axis_tickSizeOuter)
     *
     * @public
     * @type {Number}
     */
    tickSizeOuter: 8,

    /**
     * Explicit tick values for this axis.
     * [See D3 docs for more details](https://github.com/d3/d3-axis#axis_tickValues)
     *
     * @public
     * @type {Array}
     */
    tickValues: null,

    rect: null,

    offsetX: 0,

    offsetY: 0,

    didReceiveAttrs: function didReceiveAttrs() {
      Ember.run.scheduleOnce('render', this, this.drawAxis);
    },
    drawAxis: function drawAxis() {
      if (!this.element) {
        return;
      }
      var selection = (0, _d3Selection.select)(this.element);

      var rect = this.get('rect');

      var _getProperties = this.getProperties('offsetX', 'offsetY'),
          offsetX = _getProperties.offsetX,
          offsetY = _getProperties.offsetY;

      var _getProperties2 = this.getProperties('scale', 'orientation', 'tickFormat', 'ticks', 'tickSizeInner', 'tickSizeOuter', 'tickValues'),
          scale = _getProperties2.scale,
          orientation = _getProperties2.orientation,
          tickFormat = _getProperties2.tickFormat,
          ticks = _getProperties2.ticks,
          tickSizeInner = _getProperties2.tickSizeInner,
          tickSizeOuter = _getProperties2.tickSizeOuter,
          tickValues = _getProperties2.tickValues;

      var axis = this.createAxis(orientation, scale);

      axis.tickFormat(tickFormat);
      axis.tickSize(tickSizeInner, tickSizeOuter);
      axis.tickValues(tickValues);
      axis.scale(scale);

      if (ticks) {
        axis.ticks(ticks);
      }

      selection.call(axis);
      selection.attr('transform', translateByOrientation(orientation, rect, offsetX, offsetY));
    },
    createAxis: function createAxis(orient, scale) {
      return AXIS_MAP[orient](scale);
    }
  });

  AxisComponent.reopenClass({
    positionalParams: ['orientation', 'scale']
  });

  exports.default = AxisComponent;
});